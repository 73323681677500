import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 拉取首字母品牌列表
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandLetterService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/brand/category/brand/letter?menuId='+params.menuId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 拉取分类id下所有品牌
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandCategoryService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/brand/letter?categoryId='+params.categoryId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 拉取分类id下我的品牌
 * @param params
 * @returns {Promise<*>}
 */
export const getMyBrandCategoryService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/myBrand?categoryId='+params.categoryId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
