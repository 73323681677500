/**
 * 返回顶部
 * @type {{data(): {}}}
 */
import { callBackTop, isPhone } from "@/utils/tools";
const CallBack = {
  name: "CallBack",
  data() {
    return {
      callBackTop: callBackTop,
      scrollTop: "",
      callBackShow: false,
      isPhone: isPhone
    };
  },
  watch: {
    scrollTop() {
      if (this.scrollTop > 500) {
        this.callBackShow = true;
      } else {
        this.callBackShow = false;
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (!isPhone()) {
      let back = document.getElementById("back");
      back.style.top = window.innerHeight - 395 + "px"; //135
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 500) {
        this.callBackShow = true;
      }

      if (!isPhone()) {
        let back = document.getElementById("back");
        back.style.top = window.innerHeight + this.scrollTop - 395 + "px";
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
export default CallBack;
