<template>
    <div class="layout-information-wishlist">
        <!--s: type-->
        <div class="information-type">
            <a href="javascript:" @click="onSelectedType(type.value)" :class="selectedType === type.value?'active':''" :key="key" v-for="(type,key) in subscribeType">{{ type.label }}</a>
        </div>
        <!--e: type-->
        <div class="wishlist-list">
            <ul>
                <li :key="key" v-for="(wishlist,key) in wishlistData.content">
                    <img @click="onQueryDetails(wishlist.id)" :src="wishlist.images" v-lazy="wishlist.images" class="wishlist-product-img"/>
                    <div class="wishlist-content">
                        <h1 class="title">{{ wishlist.brandName }}</h1>
                        <p class="content">{{ wishlist.name }}</p>
                        <span class="price">{{ language === 'CN'?onCharacterPrice(wishlist.price) : wishlist.price }}</span>
                    </div>
                    <a href="javascript:" class="close"></a>
                </li>
            </ul>
        </div>
        <div class="wishlist-page" v-if="wishlistData.content && wishlistData.content.length!==0">
            <Page :total="wishlistData.totalElements" :current="wishlistParams.currentPage" :page-size="wishlistParams.pageSize"  @on-change="onSearchPage" @on-page-size-change="onSearchPageSize" size="small"></Page>
        </div>
        <div class="wishlist-btn" v-if="wishlistData.content && wishlistData.content.length!==0">
            <span class="tips" v-if="wishlistConfirm">{{ $t('userCenter.deleteTips') }}</span>
            <a href="javascript:" v-if="!wishlistConfirm" @click="wishlistConfirm = true">{{ $t('userCenter.deleteAll') }}</a>
            <Button shape="circle" :loading="loadingStatus" v-if="wishlistConfirm" @click="onDelAllGoodsCollectionList">
                <span v-if="!loadingStatus">{{ $t('userCenter.confirm') }}</span>
                <span v-else>{{ $t('userCenter.confirm') }}</span>
            </Button>
            <a href="javascript:" v-if="wishlistConfirm" @click="wishlistConfirm = false">{{ $t('userCenter.cancel') }}</a>
        </div>
        <div class="wishlist-loading" v-if="loadingVisible"></div>
        <!--s: tips-->
        <div class="information-no-data" v-if="wishlistData.content && wishlistData.content.length === 0">
            <div class="tips-box">
                <span class="tips-image" :style="'background-image:url('+userCenterTips.image+')'"></span>
                <div class="tips-content">
                    <div class="box">
                        <h2 class="tips-title">{{ userCenterTips.title }}</h2>
                        <p class="tips-text">{{ userCenterTips.text }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--e: tips-->
    </div>
</template>
<script>
import Wishlist from './wishlist'
export default Wishlist
</script>
<style lang="less">
@import "wishlist";
</style>
