<template>
  <div class="layout-information">
    <h1 class="information-title h5">
      <a href="javascript:" class="back" @click="onBack"></a
      >{{ $t("userCenter.searchAlertsWishlist")
      }}<a href="javascript:" class="close" @click="onClose"></a>
    </h1>
    <!--s: tabs-->
    <div class="information-tabs">
      <a
        href="javascript:"
        @click="onSelected(1)"
        :class="selected === 1 ? 'active' : ''"
        ><i
          class="icon"
          :class="selected === 1 ? 'bookmark-green' : 'bookmark-grey'"
        ></i
        >{{ $t("userCenter.search") }}<font>{{ userInfo.search }}</font></a
      >
      <a
        href="javascript:"
        @click="onSelected(2)"
        :class="selected === 2 ? 'active' : ''"
        ><i
          class="icon"
          :class="selected === 2 ? 'point-black' : 'point-grey'"
        ></i
        >{{ $t("userCenter.alerts") }}<font>{{ userInfo.alert }}</font></a
      >
      <a
        href="javascript:"
        @click="onSelected(3)"
        :class="selected === 3 ? 'active' : ''"
        ><i class="icon" :class="selected === 3 ? 'like-red' : 'like-grey'"></i
        >{{ $t("userCenter.wishlist") }}<font>{{ userInfo.wish }}</font></a
      >
    </div>
    <!--e: tabs-->

    <Search v-if="selected === 1" />
    <Alerts v-if="selected === 2" @on-user-center-info="onUserCenterInfo" />
    <Wishlist v-if="selected === 3" @on-user-center-info="onUserCenterInfo" />
  </div>
</template>
<script>
import Information from "./information";
export default Information;
</script>
<style lang="less">
@import "information";
</style>
