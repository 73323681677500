<template>
    <div class="layout-user-center">
        <div class="user-info" v-if="selectMenu === linksName.USER_MENU">
            <h1 class="user-title">{{ $t('header.account') }}<a href="javascript:" class="close" @click="onClose"></a></h1>
            <div class="user-list">
                <ul>
                    <li :key="key" v-for="(menu,key) in userMenu" @click="onLinks(menu.label)"><span class="name">{{ menu.name }}</span></li>
                </ul>
            </div>
        </div>
        <AccountInfo @on-mask="onClose" v-if="selectMenu === linksName.ACCOUNT_INFO"/>
        <Information @on-mask="onClose" v-if="selectMenu === linksName.SEARCH_REMIND_SHOPPING_LIST"/>
        <Preferences @on-mask="onClose" v-if="selectMenu === linksName.USER_PREFERENCES"/>
    </div>
</template>
<script>
import UserCenter from './userCenter'
export default UserCenter
</script>
<style lang="less">
@import "userCenter";
</style>
