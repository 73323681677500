<template>
    <div class="box">
        <div class="account-input-box">
            <div class="box-input">
                <input v-model="account" type="text" @focus="onFocus('account')" @blur="onBlur('account')" :placeholder="accountPlaceholder" class="input"/>
            </div>
<!--            <div class="box-input border-bottom">-->
<!--                <input v-model="code" type="text"  @focus="onFocus('verificationCode')" @blur="onBlur('verificationCode')" :placeholder="verificationCodePlaceholder" class="input"/>-->
<!--                <a href="javascript:" @click="onSendCode" v-if="!sendStatus">{{ $t('login.sendCode') }}</a>-->
<!--                <span class="number" v-if="sendStatus">{{ counter }}</span>-->
<!--            </div>-->
<!--            <div class="box-input border-bottom">-->
<!--                <input v-model="newPassword" :type="newPasswordVisible?'text':'password'"  @focus="onFocus('newPassword')" @blur="onBlur('newPassword')" :placeholder="newPasswordPlaceholder" class="input"/>-->
<!--                <a href="javascript:" @click="onPasswordShow">{{ newPasswordVisible?$t('login.hide'):$t('login.show') }}</a>-->
<!--            </div>-->
            <div class="box-print print-bottom" v-show="accountPrintVisible">{{ accountPrint }}</div>
        </div>
        <div class="box-forget forget-password">
            <p v-html="$t('login.retrievePasswordText')"></p>
        </div>
        <div class="box-btn password-btn">
            <Button :loading="loadingStatus" @click="onRetrieveCheck">
                <span v-if="!loadingStatus">{{ $t('login.retrievePassword') }}</span>
                <span v-else>{{ $t('login.retrievePassword') }}</span>
            </Button>
        </div>
    </div>
</template>
<script>
import RetrievePwd from './retrievePwd'
export default RetrievePwd
</script>
