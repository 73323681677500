
/**
 *  用户中心
 * @type {{}}
 */
import Cookies from 'js-cookie'
import { userLogoutService } from "@/service/user-service";
import { LINKS_NAME,userMenu } from "@/service/public/enum";
import { mapMutations,mapState } from "vuex";
import AccountInfo from '@/components/UserCenter/AccountInfo/accountInfo.vue'
import Information from '@/components/Information/information.vue'
import Preferences from '@/components/Preferences/preferences.vue'
const UserCenter = {
    name:'UserCenter',
    components:{
        AccountInfo,
        Information,
        Preferences
    },
    computed: {
        ...mapState({
            selectMenu: state => state.userCenter.selectMenu, //获取状态管理选择的菜单
        }),
    },
    data() {
        return {
            userMenu:userMenu,
            linksName:LINKS_NAME,
        }
    },
    methods:{
        ...mapMutations('userCenter',['SET_USER_ACCOUNT','SET_SELECT_MENU']),
        /**
         * 跳转
         */
        onLinks(name){
            switch (name){
                case LINKS_NAME.ACCOUNT_INFO:
                    this.SET_SELECT_MENU(name)
                    break;
                case LINKS_NAME.SEARCH_REMIND_SHOPPING_LIST:
                    this.SET_SELECT_MENU(name)
                    break;
                case LINKS_NAME.USER_PREFERENCES:
                    this.SET_SELECT_MENU(name)
                    break;
                case LINKS_NAME.USER_LOGOUT:
                    this.onLogout().then();
                    break
            }
        },
        /**
         * 关闭
         */
        onClose() {
            this.$emit('on-mask',true)
        },
        /**
         * 退出登录
         * @returns {Promise<void>}
         */
        async onLogout(){
            let confirm = window.confirm(this.$t('login.logoutConfirm'))
            if(confirm){
                try {
                    const { code } = await userLogoutService({})
                    if(code === 1){
                        Cookies.remove('token');
                        this.SET_SELECT_MENU('')
                        this.SET_USER_ACCOUNT('')
                        this.$router.push('/').then()
                        location.reload()
                    }
                }catch (error){
                    console.log(error)
                }
            }
        }
    }
}
export default UserCenter
