/**
 * 轮播图组件
 * @type {{data(): {}}}
 */
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { onCharacterPrice } from "@/utils/tools";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const History = {
    name:'History',
    props:{
        /**
         * 接收的数据
         */
        data:{
            type:Array,
            default:()=>[],
        },
    },
    computed:{
        ...mapState({
            currencyCode: state => state.system.currencyCode, //获取状态管理币种代码
        }),
    },
    data(){
        return {
            onCharacterPrice:onCharacterPrice,
            currentIndex: 0,// 当前索引
            currentSpace:0,
            number:5, //显示的条数
            scroll:0,
            offsetWidth:0,
        }
    },
    created() {
    },
    mounted() {
        if(this.data.length!==0){
            this.offsetWidth = Math.ceil(document.getElementById('historyId').offsetWidth)+1
        }
        /**
         * carousel宽
         */
        $event.$on(EVENT_NAME.CAROUSEL_OFFSET_WIDTH, () => {
            this.offsetWidth = Math.ceil(document.getElementById('historyId').offsetWidth)+1
        });
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID']),
        /**
         * 监听超卖滚动条
         */
        carouselScroll(e) {
            this.scroll = Math.ceil(e.srcElement.scrollLeft)
        },
        /**
         * 点击左侧箭头切换
         */
        onSwitchLeft(){
            if(this.scroll >= 0){
                document.getElementById('historyId').scrollLeft -= this.offsetWidth
            }else{
                document.getElementById('historyId').scrollLeft =  0
            }
            this.scroll = document.getElementById('historyId').scrollLeft
        },
        /**
         * 点击右侧箭头切换
         */
        onSwitchRight(){
            if(this.scroll <= (this.data.length*245-25)-this.offsetWidth){
                document.getElementById('historyId').scrollLeft += this.offsetWidth
            }else{
                document.getElementById('historyId').scrollLeft =  (this.data.length*245-25)-this.offsetWidth
            }
            this.scroll = document.getElementById('historyId').scrollLeft
        },
        /**
         * 点击产品跳转
         */
        onLinks(id){
            this.$emit('on-close')
            this.SET_PRODUCT_ID(id)
            $event.$emit(EVENT_NAME.LOGIN_TOKEN);
            if(this.$route.path === '/product/details'){
                $event.$emit(EVENT_NAME.PRODUCT_GROUP_DETAILS,id);
            }else{
                this.$router.push('/product/details?id='+id)
            }
        },
        /**
         * 点击喜爱回调
         * @param obj
         */
        onLike(obj){
            this.$emit('on-like',obj)
            if(!Cookies.get('token')){
                this.$emit('on-close')
                $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS)
                return
            }
            this.onGoodsCollection(obj.id,obj.isWish).then()
        },
        /**
         * 新增商品收藏
         * @returns {Promise<void>}
         */
        async onGoodsCollection(id,type) {
            try {
                let params = {
                    'productId':id,
                    'type':type === 0?1:0
                }
                const { code } = await addGoodsCollectionService(params)
                if(code === 1){
                    for(let i in this.data){
                        if(id === this.data[i].id){ //isWish
                            type === 0?this.data[i].isWish = 1:this.data[i].isWish = 0
                        }
                    }
                }
            }catch (error){
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.CAROUSEL_OFFSET_WIDTH])
    }
}
export default History
