/**
 * 商品提醒管理
 */
import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * post 新增商品提醒
 * @param params
 * @returns {Promise<*>}
 */
export const addGoodsRemindService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/shop/alert/setAlert'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询用户商品提醒列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGoodsRemindListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/alert?cid1='+params.cid1+'&page='+params.page+'&size='+params.size+'&productIds='+params.productIds))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询浏览记录列表
 * @param params
 * @returns {Promise<*>}
 */
export const getHistoryListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group?productIds='+params.productIds))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询尺码对照表
 * @param params
 * @returns {Promise<*>}
 */
export const getSizeGuideListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/size/productId?productId='+params.productId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 根据分类查询尺码对照表
 * @param params
 * @returns {Promise<*>}
 */
export const getSizeGuideCategoryIdListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/size/categoryId?categoryId='+params.categoryId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 删除商品提醒
 * @param params
 * @returns {Promise<*>}
 */
export const delGoodsRemindService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/alert/delAlert?productId='+params.productId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 删除全部商品提醒
 * @param params
 * @returns {Promise<*>}
 */
export const delAllGoodsRemindService = async () => {
    try {
        const { data } = await request.delete(evnUrl.concat('/shop/alert'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询详情页商品品牌推荐列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupBrandListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group/brand/list?id='+params.id+'&page='+params.page+'&size='+params.size+'&sort='+params.sort))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询商品相关推荐列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupRelevantListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group/relevant/list?id='+params.id+'&page='+params.page+'&size='+params.size+'&sort='+params.sort+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询商品标题下推荐列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupTitleListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group/title/list?id='+params.id+'&page='+params.page+'&size='+params.size+'&sort='+params.sort))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询搜索信息
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketSearchFastService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/search/get/fast?cid1='+params.cid1+'&osType=1'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询模糊搜索信息
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketSearchService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/search?cid1='+params.cid1+'&keyword='+params.keyword))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
