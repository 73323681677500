<template>
    <div class="account-handle-box">
        <div class="box-input">
            <span class="box-input-text">{{ account }}</span>
        </div>
        <div class="box-input">
            <input v-model="newAccount" type="text" :placeholder="$t('login.newAccount')" class="input"/>
        </div>
        <div class="box-input">
            <input v-model="password" :type="passwordVisible?'text':'password'" :placeholder="$t('login.password')"  class="input"/>
            <a href="javascript:"  @click="onPasswordShow">{{ passwordVisible?$t('login.hide'):$t('login.show') }}</a>
        </div>
        <div class="input-tips" v-show="tipsVisible">{{ tipsValue }}</div>
        <div class="box-btn">
            <div class="box">
                <van-button :loading="loadingStatus" type="info" @click="onRestAccountCheck">
                  <span v-if="!loadingStatus">{{ $t('login.resetEmail') }}</span>
                  <span v-else>{{ $t('login.resetEmail')}}</span>
                </van-button>
                <a href="javascript:" @click="onRestPasswordEvent">{{ $t('login.resetPassword') }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import RestEmail from './restEmail'
export default RestEmail
</script>
<style lang="less">
@import "restEmail";
</style>
