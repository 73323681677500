<template>
  <div class="layout-body" id="layoutBody">
    <div class="layout-box" id="layoutBox">
      <!--s: Header-->
      <Header @on-select-type="onSelectType" @on-mask="onMask"/>
      <!--e: Header-->

      <!--s: login-->
      <div
        class="layout-login-body"
        v-if="logonVisible && !isPhone()"
        :style="$route.path === '/pre' ? 'top:65px' : ''"
      >
        <div class="low-lining" @click="onClose"></div>
        <Login :logonName="logonName" />
      </div>
      <!--e: login-->

      <router-view v-if="isPhone()"></router-view>

      <div
        class="layout-content"
        v-if="!isPhone()"
        :style="$route.path === '/pre' ? 'top:65px' : ''"
      >
        <router-view></router-view>
      </div>

      <div class="layout-footer" v-if="((!showListFilter && !showBrandFilter) && isPhone()) || !isPhone()">
        <!--s: Advert-->
        <div
          class="layout-advert"
          v-if="!isPhone() && footerBannerList && $route.path !== '/pre'"
          :style="'background-image: url(' + footerBannerList.coverImage + ')'"
        >
          <h3
            class="advert-name"
            :style="
              'font-size:' +
              footerBannerList.ctSize +
              'px;line-height:' +
              footerBannerList.ctLeading +
              'px;text-align:' +
              footerBannerList.ctLocation +
              ';color:#' +
              footerBannerList.ctColour
            "
          >
            {{ footerBannerList.coverTitle }}
          </h3>
        </div>
        <!--e: Advert-->

        <div
          class="mask"
          v-if="maskVisible && isPhone()"
          @click="onCloseMask"
        ></div>

        <!--s: Footer-->
        <Footer />
        <!--e: Footer-->
      </div>

      <!--s: CallBack Top-->
      <CallBack v-if="!isPhone()" />
      <!--s: CallBack Top-->
    </div>

    <!--s: Remind-->
    <div class="layout-remind" v-if="remindStatus">
      <div class="text">
        <p>{{ $t("remind.text") }}</p>
      </div>
      <a href="javascript:" @click="onRemind">{{ $t("remind.button") }}</a>
    </div>
    <!--e: Remind-->
  </div>
</template>
<script>
import Index from "./index";
export default Index;
</script>
<style lang="less">
@import "index";
</style>
