<template>
    <div class="layout-history-box">
        <h1 class="history-title">{{ $t('header.historyTitle') }}</h1>
        <div class="history-no" v-if="data && data.length===0">
            <div class="box">
                <span class="tips-image"></span>
                <div class="tips-text">
                    <div class="content">
                        <h1 class="title">{{ $t('header.historyTipsTitle') }}</h1>
                        <p class="text">{{ $t('header.historyTipsText') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-content" v-else>
            <a href="javascript:" class="carousel-btn" :class="scroll===0?'carousel-grey-l':'carousel-black-l'" @click="onSwitchLeft"></a>
            <div class="history-list" id="historyId" @scroll="carouselScroll">
                <ul v-if="data" :style="'width:'+((data.length)*245-25)+'px'">
                    <li :key="key" v-for="(history,key) in data" >
                        <div class="list-handle">
                            <a href="javascript:" class="handle-unified like" @click="onLike(history)" :class="history.isWish === 1?'like':'like-grey'"></a>
                            <a href="javascript:" class="handle-unified star" v-if="history.isStar === 1"></a>
                            <a href="javascript:" class="handle-unified draft" v-if="history.isSole === 1"></a>
                        </div>
                        <img class="carousel-image" @click="onLinks(history.id)" v-lazy="history.images"/>
                        <div class="carouse-text">
                            <p class="carouse-content">{{ history.brandName }}</p>
                            <p class="carouse-content">{{ currencyCode === 'cny'?onCharacterPrice(history.price) : history.price }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <a href="javascript:" class="carousel-btn" :class="scroll>=(data.length*245-25)-offsetWidth?'carousel-grey-r':'carousel-black-r'" @click="onSwitchRight"></a>
        </div>
    </div>
</template>
<script>
import History from './history'
export default History
</script>
<style lang="less">
@import "history";
</style>
