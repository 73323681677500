/**
 * 脚组件
 * @type {{data(): {}}}
 */
import { serviceMenu, subscribeTypeFooter } from "@/service/public/enum";
import { validateEmail, isPhone } from "@/utils/tools";
import { userAccountSubscribeService } from "@/service/user-service";
const Footer = {
  name: "Footer",
  computed: {
    onSubStyle() {
      return (value) => {
        let style = "";
        if (value === 1) {
          style = "width:50px";
        }
        if (value === 2) {
          style = "width:28px";
        }
        if (value === 3) {
          style = "width:27px";
        }
        if (value === 4) {
          style = "width:38px";
        }
        return style;
      };
    }
  },
  data() {
    return {
      serviceMenu: serviceMenu,
      subscribeStatus: false, //订阅状态
      emailTipsStatus: false,
      account: "",
      accountPlaceholder: this.$t("login.account"),
      subscribeTypeFooter: subscribeTypeFooter, //订阅分类数据
      loadingStatus: false,
      madelVisible: false,
      isPhone: isPhone
    };
  },
  methods: {
    onFocus() {
      this.accountPlaceholder = "";
    },
    onBlur() {
      this.accountPlaceholder = this.$t("login.account");
    },
    /**
     * 选中订阅
     */
    onSignupCheck(id) {
      for (let i in subscribeTypeFooter) {
        if (id === subscribeTypeFooter[i].value) {
          subscribeTypeFooter[i].checkBox
            ? (subscribeTypeFooter[i].checkBox = 0)
            : (subscribeTypeFooter[i].checkBox = 1);
        }
      }
    },
    /**
     * 跳转
     * @param links
     */
    onLinks(links) {
      this.$router.push(links);
    },
    /**
     * 提交选中订阅
     */
    onSignupSubmit() {
      if (!validateEmail(this.account)) {
        this.emailTipsStatus = true;
        return;
      }
      this.emailTipsStatus = false;
      this.onAccountSubscribe().then();
    },
    /**
     * 订阅消息
     * @returns {Promise<void>}
     */
    async onAccountSubscribe() {
      try {
        this.loadingStatus = true;
        let params = {
          email: this.account,
          home: subscribeTypeFooter[3].checkBox,
          kids: subscribeTypeFooter[2].checkBox,
          men: subscribeTypeFooter[1].checkBox,
          women: subscribeTypeFooter[0].checkBox
        };
        const { code } = await userAccountSubscribeService(params);
        if (code === 1) {
          this.subscribeStatus = true;
          this.account = "";
          for (let i in subscribeTypeFooter) {
            subscribeTypeFooter[i].checkBox = 0;
          }
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    }
  }
};
export default Footer;
