var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-body",attrs:{"id":"layoutBody"}},[_c('div',{staticClass:"layout-box",attrs:{"id":"layoutBox"}},[_c('Header',{on:{"on-select-type":_vm.onSelectType,"on-mask":_vm.onMask}}),(_vm.logonVisible && !_vm.isPhone())?_c('div',{staticClass:"layout-login-body",style:(_vm.$route.path === '/pre' ? 'top:65px' : '')},[_c('div',{staticClass:"low-lining",on:{"click":_vm.onClose}}),_c('Login',{attrs:{"logonName":_vm.logonName}})],1):_vm._e(),(_vm.isPhone())?_c('router-view'):_vm._e(),(!_vm.isPhone())?_c('div',{staticClass:"layout-content",style:(_vm.$route.path === '/pre' ? 'top:65px' : '')},[_c('router-view')],1):_vm._e(),(((!_vm.showListFilter && !_vm.showBrandFilter) && _vm.isPhone()) || !_vm.isPhone())?_c('div',{staticClass:"layout-footer"},[(!_vm.isPhone() && _vm.footerBannerList && _vm.$route.path !== '/pre')?_c('div',{staticClass:"layout-advert",style:('background-image: url(' + _vm.footerBannerList.coverImage + ')')},[_c('h3',{staticClass:"advert-name",style:('font-size:' +
            _vm.footerBannerList.ctSize +
            'px;line-height:' +
            _vm.footerBannerList.ctLeading +
            'px;text-align:' +
            _vm.footerBannerList.ctLocation +
            ';color:#' +
            _vm.footerBannerList.ctColour)},[_vm._v(" "+_vm._s(_vm.footerBannerList.coverTitle)+" ")])]):_vm._e(),(_vm.maskVisible && _vm.isPhone())?_c('div',{staticClass:"mask",on:{"click":_vm.onCloseMask}}):_vm._e(),_c('Footer')],1):_vm._e(),(!_vm.isPhone())?_c('CallBack'):_vm._e()],1),(_vm.remindStatus)?_c('div',{staticClass:"layout-remind"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.$t("remind.text")))])]),_c('a',{attrs:{"href":"javascript:"},on:{"click":_vm.onRemind}},[_vm._v(_vm._s(_vm.$t("remind.button")))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }