import $event, {EVENT_NAME} from "@/utils/event-emitter";
import { tipsData } from '@/service/public/enum'
import SignIn from '@/components/Login/SignIn/signIn.vue'
import Register from '@/components/Login/Register/register.vue'
import RetrievePwd from '@/components/Login/RetrievePwd/retrievePwd.vue'
import { facebookLogin } from "../../service/auth/fb";

const Login = {
    name:'Login',
    props:{
        /**
         * 接收的数据
         */
        logonName:{
            type:String,
        }
    },
    components:{
        SignIn,
        Register,
        RetrievePwd,
    },
    data(){
        return{
            tipsVisible:false,
            tipsDataObj:[], //随机提示数据
            tipsValue:'',
            timer:'', //定时器对象
        }
    },
    created() {
        
        console.log(this.logonName)
        /**
         * 订阅操作提示事件
         */
        $event.$on(EVENT_NAME.LOGIN_POINT_OUT, (tips) => {
            this.tipsValue = tips
            this.tipsVisible = true;
            this.onTimer()
        });
        /**
         * 订阅切换登录提示事件
         */
        $event.$on(EVENT_NAME.LOGIN_SWITCH_TIPS,() => {
            this.onTips()
        })
        this.onTips()
    },
    methods:{
        /**
         * 关闭弹框
         */
        onClose(){
            $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE)
        },
        /**
         * 计时器
         */
        onTimer() {
            this.timer = setInterval(() => {
                this.tipsVisible = false;
            }, 3000)
        },
        /**
         * 提示方法
         */
        onTips(){
            this.tipsDataObj = tipsData[Math.floor(Math.random() * tipsData.length)];
        },
        loginFB() {
            facebookLogin()
        }
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.LOGIN_POINT_OUT,EVENT_NAME.LOGIN_SWITCH_TIPS])
    }
}
export default Login
