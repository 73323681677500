/**
 *  提醒
 * @type {{}}
 */
import Vue from 'vue'
import { Page } from 'iview';
import { subscribeType, userCenterTips } from "@/service/public/enum";
import { getGoodsRemindListService,delGoodsRemindService,delAllGoodsRemindService } from "@/service/goods-remind-service";
import VueLazyLoad from "vue-lazyload";
import { mapMutations, mapState } from "vuex";
import { onCharacterPrice } from "@/utils/tools";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
Vue.component('Page', Page);
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const Alerts = {
    name:'Alerts',
    computed:{
        ...mapState({
            language: state => state.language.code,  //获取状态语言
        }),
    },
    data() {
        return {
            onCharacterPrice:onCharacterPrice,
            loadingStatus:false,
            loadingVisible:false,
            alertsConfirm:false, // 删除确认状态
            selectedType:subscribeType[0].value, //选中的分类
            subscribeType:subscribeType, //分类数据
            alertsData:{}, //数据
            userCenterTips:userCenterTips[1],//提示数据
            alertsParams:{
                currentPage:1,
                pageSize:10
            },
        }
    },
    created() {
        this.onGoodsRemindList().then()
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID']),
        ...mapMutations('userCenter',['DEL_ALERT_NEW']),
        /**
         * 切换分类
         * @param value
         */
        onSelectedType(value){
            this.selectedType = value
            this.onGoodsRemindList().then()
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.alertsParams.currentPage = number
            this.onGoodsRemindList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.alertsParams.pageSize = number
            this.onGoodsRemindList().then()
        },
        /**
         * 查询用户商品收藏列表
         * @returns {Promise<void>}
         */
        async onGoodsRemindList() {
            try {
                this.alertsData = {}
                this.loadingVisible = true
                let params = {
                    productIds:'',
                    cid1:this.selectedType,
                    page:this.alertsParams.currentPage,
                    size:this.alertsParams.pageSize
                }
                const { code,data } = await getGoodsRemindListService(params)
                if(code === 1){
                    this.alertsData = data
                }
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
        /**
         * 跳转到详情页
         * @param id
         */
        onQueryDetails(id){
            this.SET_PRODUCT_ID(id)
            this.DEL_ALERT_NEW(id)
            $event.$emit(EVENT_NAME.LOGIN_TOKEN);
            this.$router.push('/product/details?id='+id)
        },
        /**
         * 删除商品提醒
         * @returns {Promise<void>}
         */
        async onDelGoodsRemindList(id) {
            try {
                let params = {
                    productId:id,
                }
                const { code } = await delGoodsRemindService(params)
                if(code === 1){
                    this.onGoodsRemindList().then()
                    this.DEL_ALERT_NEW(id)
                    $event.$emit(EVENT_NAME.LOGIN_TOKEN);
                    this.$emit('on-user-center-info')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除全部商品收藏
         * @returns {Promise<void>}
         */
        async onDelAllGoodsRemindList() {
            try {
                this.loadingStatus = true
                const { code } = await delAllGoodsRemindService()
                if(code === 1){
                    this.alertsConfirm = false
                    this.onGoodsRemindList().then()
                    this.$emit('on-user-center-info')
                    this.DEL_ALERT_NEW('')
                    $event.$emit(EVENT_NAME.LOGIN_TOKEN);
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
    },
}
export default Alerts
