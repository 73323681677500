/**
 *  用户偏好
 * @type {{}}
 */
import {
  subscribeType,
  radioType,
  language,
  currency,
  LINKS_NAME
} from "@/service/public/enum";
import {
  getUserPreferenceService,
  addUserPreferenceService
} from "@/service/goods-collection-service";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const Preferences = {
  name: "Preferences",
  computed: {
    ...mapState({
      langCode: (state) => state.language.code, //获取状态管理语言代码
      countryList: (state) => state.system.countryList //获取状态管理国家数据
    })
  },
  data() {
    return {
      loadingStatus: false,
      tipsVisible: false, //提示可显示
      timer: "", //定时器对象
      language: language, //选择语言
      languageSel: {}, //选中的语言
      countrySel: {}, //选中的国家
      currencySel: {}, //选中的币种
      languageVisible: false,
      countryVisible: false,
      currencyVisible: false,
      currency: currency, //币种
      subscribeType: subscribeType, //订阅分类数据
      subscribeValue: false, //是否取消订阅
      radioType: radioType, //个人中心单选数据
      radioSelected: radioType[0].value,
      userId: ""
    };
  },
  created() {
    this.onUserPreference().then();
  },
  methods: {
    ...mapMutations("system", ["SET_CURRENCY_CODE"]),
    ...mapMutations("userCenter", ["SET_SELECT_MENU"]),

    onBack() {
      this.SET_SELECT_MENU(LINKS_NAME.USER_MENU);
    },
    /**
     * 关闭
     */
    onClose() {
      this.$emit("on-mask", true);
    },
    /**
     * 计时器
     */
    onTimer() {
      this.timer = setInterval(() => {
        this.tipsVisible = false;
      }, 2000);
    },
    /**
     * 选择语言
     * @param lang
     */
    onSelectLanguage(lang) {
      this.languageSel = lang;
      this.languageVisible = false;
    },
    /**
     * 选择币种
     * @param cur
     */
    onSelectCurrency(cur) {
      this.currencySel = cur;
      this.currencyVisible = false;
    },
    /**
     * 选择国家
     * @param count
     */
    onSelectCount(count) {
      this.countrySel = count;
      this.countryVisible = false;
    },
    /**
     * 选中订阅
     */
    onSelectCheck(id) {
      for (let i in subscribeType) {
        if (id === subscribeType[i].value) {
          subscribeType[i].checkBox
            ? (subscribeType[i].checkBox = 0)
            : (subscribeType[i].checkBox = 1);
        }
      }
      // for(let i in subscribeType){
      //     if(subscribeType[i].checkBox === 0){
      //         this.subscribeValue = false;
      //         return
      //     }else{
      //         this.subscribeValue = true
      //     }
      // }
    },
    /**
     * 是否取消订阅
     */
    onSelectRadio() {
      // if(this.subscribeValue){
      //     this.subscribeValue = false
      //     for(let i in subscribeType){
      //         subscribeType[i].checkBox = 0
      //     }
      // }else{
      //     for(let i in subscribeType){
      //         subscribeType[i].checkBox = 1
      //     }
      //     this.subscribeValue = true
      // }
      for (let i in subscribeType) {
        subscribeType[i].checkBox = 0;
      }
    },
    /**
     * 切换选择促销和通知
     * @param value
     */
    onRadio(value) {
      this.radioSelected = value;
    },
    /**
     * 查询用户偏好
     * @returns {Promise<void>}
     */
    async onUserPreference() {
      try {
        const { code, data } = await getUserPreferenceService();
        if (code === 1) {
          for (let i in language) {
            if (language[i].languageId === data.languageId) {
              this.languageSel = language[i];
            }
          }
          for (let i in currency) {
            if (currency[i].code === data.currency) {
              this.currencySel = currency[i];
            }
          }
          for (let i in this.countryList) {
            for (let j in this.countryList[i].list) {
              if (
                localStorage.getItem("countryCode") ===
                this.countryList[i].list[j].countryCode
              ) {
                this.countrySel = this.countryList[i].list[j];
                break;
              }
            }
          }
          this.subscribeType[0].checkBox = data.women;
          this.subscribeType[1].checkBox = data.men;
          this.subscribeType[2].checkBox = data.kids;
          this.subscribeType[3].checkBox = data.home;
          this.radioSelected = data.pan;
          this.userId = data.id;
          this.SET_CURRENCY_CODE(data.currency);
          $event.$emit(EVENT_NAME.UPDATE_CURRENCY);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 设置用户偏好
     * @returns {Promise<void>}
     */
    async onAddUserPreference() {
      try {
        this.loadingStatus = true;
        let params = {
          countryId: this.countrySel.id,
          languageId: this.languageSel.languageId,
          currency: this.currencySel.code,
          women: this.subscribeType[0].checkBox,
          men: this.subscribeType[1].checkBox,
          kids: this.subscribeType[2].checkBox,
          home: this.subscribeType[3].checkBox,
          pan: this.radioSelected,
          id: this.userId
        };
        const { code } = await addUserPreferenceService(params);
        if (code === 1) {
          this.tipsVisible = true;
          this.onTimer();
          this.onUserPreference().then();
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    }
  }
};
export default Preferences;
