import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 查询Top Mid Tail三种类型banner
 * @param params
 * @returns {Promise<*>}
 */
export const getBannerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/banner?categoryId='+params.categoryId+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 根据分类查询超卖
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketSuperSaleService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/superSale?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询首页精选
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketSelectedListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/selected/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询首页发现
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketDiscoverListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/discover?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询发现列表
 * @param params
 * @returns {Promise<*>}
 */
export const getDiscoverListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/discover/list?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询首页品牌周刊
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketBrandWeeklyListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/brandWeekly?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询首页特色
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketFeaturedListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/featured?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询色列表
 * @param params
 * @returns {Promise<*>}
 */
export const getFeaturedListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/featured/list?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询市场活动分组商品列表
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketProductListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/group/product?brandIds='+params.brandIds+'&attributeIds='+params.attributeIds+'&price2='+params.price2+'&specs='+params.specs+'&id='+params.id+'&cid1'+params.cid1+'&cid2='+params.cid2+'&cid3='+params.cid3+'&cid4='+params.cid4+'&cid5='+params.cid5+'&size='+params.size+'&page='+params.page+'&isNew='+params.isNew+'&isSale='+params.isSale+'&isOld='+params.isOld+'&sortBy='+params.sortBy))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询图文
 * @param params
 * @returns {Promise<*>}
 */
export const getMarketContentService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/market/get/content?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
