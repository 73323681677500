<template>
    <div class="box">
        <div class="account-input-box">
            <div class="box-input">
                <input v-model="account" type="text"  @focus="onFocus('account')" @blur="onBlur('account')" :placeholder="accountPlaceholder" class="input"/>
            </div>
            <div class="box-input border-bottom">
                <input v-model="password" :type="passwordVisible?'text':'password'" @focus="onFocus('password')" @blur="onBlur('password')"  :placeholder="passwordPlaceholder" class="input"/>
                <a href="javascript:" @click="onPasswordShow">{{ passwordVisible?$t('login.hide'):$t('login.show') }}</a>
            </div>
            <div class="box-print" v-show="accountPrintVisible">{{ accountPrint }}</div>
        </div>
        <div class="box-btn">
            <Button shape="circle" :loading="loadingStatus" @click="onSignInCheck">
                <span v-if="!loadingStatus">{{ $t('login.loginBtn') }}</span>
                <span v-else>{{ $t('login.loginBtn') }}</span>
            </Button>
        </div>
        <div class="box-forget">
            <a href="javascript:" @click="onForgotPassword">{{ $t('login.forgetPassword') }}</a>
        </div>
    </div>
</template>
<script>
import SignIn from './signIn'
export default SignIn
</script>
