<template>
    <div class="layout-account-info">
        <h1 class="account-title"><a href="javascript:" class="back" @click="onBack"></a>{{ displayStatus? $t('login.resetEmail') : $t('login.resetPassword') }}<a href="javascript:" class="close" @click="onClose"></a></h1>
        <RestEmail @on-account-subassembly_status="onAccountSubassemblyStatus" v-if="displayStatus"/>
        <RestPassword @on-account-subassembly_status="onAccountSubassemblyStatus" v-if="!displayStatus"/>
    </div>
</template>
<script>
import AccountInfo from './accountInfo'
export default AccountInfo
</script>
<style lang="less">
@import "accountInfo";
</style>
