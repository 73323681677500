import Vue from 'vue'
import { Button } from 'iview';
Vue.component('Button', Button);
import $event, {EVENT_NAME} from "@/utils/event-emitter";
import { validateEmail, validatePassword } from "@/utils/tools";
import { userRegisterService } from '@/service/user-service'
const Register = {
    name:'Register',
    data() {
        return {
            loadingStatus:false,
            passwordVisible:false,
            accountPrintVisible:false,
            account:'',
            password:'',
            accountPrint:'',
            accountPlaceholder:this.$t('login.account'),
            passwordPlaceholder:this.$t('login.password'),
        }
    },
    mounted() {
        // window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        onFocus(name){
            if(name === 'account'){
                this.accountPlaceholder = ''
            }
            if(name === 'password'){
                this.passwordPlaceholder = ''
            }
        },
        onBlur(name){
            if(name === 'account'){
                this.accountPlaceholder = this.$t('login.account')
            }
            if(name === 'password'){
                this.passwordPlaceholder = this.$t('login.password')
            }
        },
        /**
         * 密码可显示
         */
        onPasswordShow() {
            this.passwordVisible?this.passwordVisible = false:this.passwordVisible = true
        },
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onRegisterInCheck()
            }
        },
        /**
         *  校验方法 目前只校验账户密码是否为空 通过之后开启防抖函数 调用登录方法
         *  @param account && password
         */
        onRegisterInCheck() {
            if(!validateEmail(this.account)){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.emailTips')
                return
            }
            if(!validatePassword(this.password)){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.passwordTips')
                return
            }
            this.accountPrintVisible = false
            this.loadingStatus = true
            this.onRegister().then()
        },

        /**
         * 注册方法
         * @returns {Promise<void>}
         */
        async onRegister() {
            try {
                let params = {
                    email:this.account,
                    password:this.password
                }
                const { code } = await userRegisterService(params)
                if(code === 1){
                    this.onSignInEvent()
                    $event.$emit(EVENT_NAME.LOGIN_POINT_OUT,this.$t('login.registerSuccessTips'));
                }
                if(code === 1003){
                    this.accountPrintVisible = true
                    this.accountPrint = this.$t('login.registeredPrompt')
                }
                this.loadingStatus = false

            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        // /**
        //  * 忘记密码
        //  * @returns links
        //  */
        // onForgetPassWord() {
        //     window.open( getForgetPassWord )
        // },
        /**
         * 发送登录、注册组件状态事件
         */
        onSignInEvent() {
            $event.$emit(EVENT_NAME.LOGIN_SWITCH_TIPS);
            $event.$emit(EVENT_NAME.OPEN_LOGON,'SignIn')
        },
    },
    destroyed() {
        // window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default Register
