<template>
    <div class="box">
        <div class="account-input-box">
            <div class="box-input">
                <input v-model="account" type="text" @focus="onFocus('account')" @blur="onBlur('account')" :placeholder="accountPlaceholder" class="input"/>
            </div>
            <div class="box-input border-bottom">
                <input v-model="password" :type="passwordVisible?'text':'password'" @focus="onFocus('password')" @blur="onBlur('password')"  :placeholder="passwordPlaceholder" class="input"/>
                <a href="javascript:" @click="onPasswordShow">{{ passwordVisible?$t('login.hide'):$t('login.show') }}</a>
            </div>
            <div class="box-print" v-show="accountPrintVisible">{{ accountPrint }}</div>
        </div>
        <div class="box-btn">
            <Button :loading="loadingStatus" @click="onRegisterInCheck">
                <span v-if="!loadingStatus">{{ $t('login.registerBtn') }}</span>
                <span v-else>{{ $t('login.registerBtn') }}</span>
            </Button>
        </div>
        <div class="box-forget forget">
            <p v-html="$t('login.registerText')"></p>
        </div>
    </div>
</template>
<script>
import Register from './register'
export default Register
</script>
