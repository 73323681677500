
/**
 *  账号信息
 * @type {{}}
 */
import { LINKS_NAME } from "@/service/public/enum";
import { mapMutations } from "vuex";
import RestEmail from "@/components/UserCenter/AccountInfo/RestEmail/restEmail.vue";
import RestPassword from "@/components/UserCenter/AccountInfo/RestPassword/restPassword.vue";
const AccountInfo = {
    name:'AccountInfo',
    components:{ RestEmail,RestPassword },
    data() {
        return {
            displayStatus:true, //组件显示状态
            linksName:LINKS_NAME,
        }
    },
    methods:{
        ...mapMutations('userCenter',['SET_SELECT_MENU']),
        /**
         * 返回
         */
        onBack(){
            this.SET_SELECT_MENU(LINKS_NAME.USER_MENU)
        },
        /**
         * 关闭
         */
        onClose() {
            this.$emit('on-mask',true)
        },
        /**
         * 更改登录、注册组件状态
         */
        onAccountSubassemblyStatus(){
            this.displayStatus?this.displayStatus = false:this.displayStatus = true
        }
    }
}
export default AccountInfo
