<template>
  <footer class="footer-body">
    <div class="footer-signup" v-if="!isPhone()">
      <div class="signup-name">
        <span @click="madelVisible = true">{{
          $t("footer.newsletterSignup")
        }}</span>
        <div class="signup-box" v-if="madelVisible">
          <div class="signup-content-box" v-if="!subscribeStatus">
            <span class="tips" v-if="emailTipsStatus">{{
              $t("login.emailTips")
            }}</span>
            <div class="signup-email">
              <input
                type="text"
                v-model="account"
                class="input"
                @focus="onFocus"
                @blur="onBlur"
                :placeholder="accountPlaceholder"
              />
            </div>
            <div class="signup-label">
              <div class="box">
                <ul>
                  <li
                    :key="key"
                    v-for="(subscribe, key) in subscribeTypeFooter"
                    @click="onSignupCheck(subscribe.value)"
                  >
                    <i
                      class="label-icon"
                      :class="subscribe.checkBox ? 'select' : 'selected'"
                    ></i>
                    <span
                      class="label-name"
                      :class="subscribe.checkBox ? 'title' : ''"
                      :style="onSubStyle(subscribe.value)"
                      >{{ subscribe.label }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="signup-text" v-html="$t('signup.textTips')"></div>
            <Button :loading="loadingStatus" @click="onSignupSubmit">
              <span v-if="!loadingStatus">{{ $t("signup.btn") }}</span>
              <span v-else>{{ $t("signup.btn") }}</span>
            </Button>
          </div>
          <div class="signup-content-box signup-flax" v-if="subscribeStatus">
            <div class="signup-tips">
              <span class="tips-image"></span>
              <div class="tips-content">
                <div class="box">
                  <h2 class="tips-title">{{ $t("signup.thanks") }}</h2>
                  <p class="tips-text">{{ $t("signup.submitText") }}</p>
                </div>
              </div>
            </div>
          </div>
          <a
            href="javascript:"
            class="close"
            @click="
              madelVisible = false;
              subscribeStatus = false;
            "
          ></a>
        </div>
      </div>
      <a href="javascript:" class="signup-face-book"></a>
      <a href="javascript:" class="signup-camera"></a>
      <a href="javascript:" class="signup-wx"></a>
      <a href="javascript:" class="signup-sina"></a>
    </div>
    <div class="footer-link" v-if="!isPhone()">
      <a href="javascript:" @click="onLinks(serviceMenu[0].label)">{{
        $t("footer.helpCentre")
      }}</a>
      <a href="javascript:" @click="onLinks(serviceMenu[1].label)">{{
        $t("footer.contactUs")
      }}</a>
      <a href="javascript:" @click="onLinks(serviceMenu[2].label)">{{
        $t("footer.aboutUs")
      }}</a>
      <a href="javascript:" @click="onLinks(serviceMenu[3].label)">{{
        $t("footer.accessibility")
      }}</a>
      <a href="javascript:" @click="onLinks(serviceMenu[4].label)">{{
        $t("footer.termsConditions")
      }}</a>
      <a href="javascript:" @click="onLinks(serviceMenu[5].label)">{{
        $t("footer.privacyPolicy")
      }}</a>
      <a href="javascript:">© 2021, 33boxes</a>
    </div>
    <div class="box" v-if="isPhone()">
      <div class="footer-signup">
        <span class="signup-name">{{ $t("footer.newsletterSignup") }}</span>
        <a href="javascript:" class="signup-face-book"></a>
        <a href="javascript:" class="signup-camera"></a>
        <a href="javascript:" class="signup-wx"></a>
        <a href="javascript:" class="signup-sina"></a>
      </div>
      <div class="footer-link">
        <a href="javascript:" @click="onLinks(serviceMenu[0].label)">{{
          $t("footer.helpCentre")
        }}</a>
        <a href="javascript:" @click="onLinks(serviceMenu[1].label)">{{
          $t("footer.contactUs")
        }}</a>
        <a href="javascript:" @click="onLinks(serviceMenu[2].label)">{{
          $t("footer.aboutUs")
        }}</a>
      </div>
      <div class="footer-link">
        <a href="javascript:" @click="onLinks(serviceMenu[3].label)">{{
          $t("footer.accessibility")
        }}</a>
        <a href="javascript:" @click="onLinks(serviceMenu[4].label)">{{
          $t("footer.termsConditions")
        }}</a>
        <a href="javascript:" @click="onLinks(serviceMenu[5].label)">{{
          $t("footer.privacyPolicy")
        }}</a>
      </div>
    </div>
    <div class="info" v-if="isPhone()">
      <a href="javascript:">© 2021, 33boxes</a>
    </div>
  </footer>
</template>
<script>
import Footer from "./footer";
export default Footer;
</script>
<style lang="less">
@import "footer";
</style>
