/**
 * 自定义下拉框组件
 * @type {{data(): {}}}
 */
const CustomSelect = {
    name:'CustomSelect',
    props:{
        /**
         * 监听是否展示
         */
        value: {
            type: Boolean,
            default: false
        },
        /**
         * title
         */
        title:{
            type:String | Number,
        },
        /**
         * 弹框展示的方向
         */
        placement:{
            type:String,
            default: 'left'
        },
        /**
         * 选择展开方式
         */
        show:{
            type:String,
        },
        /**
         * icon是否展示
         */
        icon:{
            type:String,
        },
        /**
         * 定位
         */
        position:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            visible:this.value,
        }
    },
    mounted() {
        /**
         * 点击空白处消除弹框
         */
        document.addEventListener('mouseup',(e) => {
            let thisId = document.getElementById('customId')
            if(thisId){
                if(!thisId.contains(e.target)){
                    this.visible = false
                }
            }
        })
    },
    methods:{
        onShow(){
            this.visible = true
            this.$emit('input', true);
        },
        onHide(){
            this.$emit('input', false);
            this.visible = false
        },
    },
    watch: {
        value(val) {
            this.visible = val;
        },
    }
}
export default CustomSelect
