/**
 *  综合信息、我的搜索/提醒/购物清单
 * @type {{}}
 */
 import { mapMutations } from "vuex";
import Search from "@/components/Information/Search/search.vue";
import Alerts from "@/components/Information/Alerts/alerts.vue";
import Wishlist from "@/components/Information/Wishlist/wishlist.vue";
import { userCenterInfoService } from "@/service/user-service";
import { LINKS_NAME } from "@/service/public/enum";
const Information = {
  name: "Information",
  components: { Search, Alerts, Wishlist },
  data() {
    return {
      selected: 1, //tab类型
      userInfo: {} //用户信息数据集
    };
  },
  created() {
    if (this.$route.query.wish == 1) {
      this.selected = 2;
    }
    this.onUserCenterInfo().then();
  },
  methods: {
    ...mapMutations("userCenter", [
      "SET_SELECT_MENU"
    ]),
    onBack() {
      this.SET_SELECT_MENU(LINKS_NAME.USER_MENU);
    },
    /**
     * 关闭
     */
    onClose() {
      this.$emit("on-mask", true);
    },
    /**
     * 选择切换标题
     * @param n
     */
    onSelected(n) {
      this.selected = n;
    },
    /**
     * 获取用户中心信息
     * @returns {Promise<void>}
     */
    async onUserCenterInfo() {
      try {
        const { code, data } = await userCenterInfoService();
        if (code === 1) {
          this.userInfo = data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
export default Information;
