<template>
    <div class="layout-custom-select" :class="show === 'mouseover'?'custom-over '+position:''+position">
        <span class="custom-title" @click="onShow"><i :class="icon" v-if="icon"></i><b v-if="icon">{{ title }}</b>{{ !icon?title:'' }}</span>
        <div class="custom-box" id="customId" v-show="visible" :style="placement === 'left'?'left:-30px;text-align:left':'right:-30px;text-align:right'">
            <slot></slot>
            <a href="javascript:" class="custom-up" @click="onHide">
                <i class="ivu-icon ivu-icon-ios-arrow-up"></i>
            </a>
        </div>
    </div>
</template>
<script>
import CustomSelect from './customSelect'
export default CustomSelect
</script>
<style lang="less">
@import "customSelect";
</style>
