import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import CallBack from "@/components/CallBack/callBack.vue";
import Login from "@/components/Login/login.vue";
import { getBannerService } from "@/service/market-service";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
// import Cookies from "js-cookie";
import { isPhone } from "@/utils/tools";
const IndexPage = {
  name: "IndexPage",
  components: {
    Header,
    Login,
    Footer,
    CallBack
  },
  computed: {
    ...mapState({
      menuType: (state) => state.product.menuType || localStorage.getItem('cid'), //获取状态管理菜单分类
      showListFilter: (state) => state.product.showListFilter, //获取状态管理是否是展示商品筛选界面
      showBrandFilter: (state) => state.product.showBrandFilter //获取状态管理是否是展示品牌筛选界面
    })
  },
  data() {
    return {
      footerBannerList: {},
      logonVisible: false,
      logonName: "",
      remindStatus: false,
      searchType: 1,
      isPhone: isPhone,
      maskVisible: false
    };
  },
  created() {
    if (localStorage.getItem("remind") && localStorage.getItem("remind") == 1) {
      this.remindStatus = false;
    } else {
      this.remindStatus = true;
    }
    if (!localStorage.getItem('cid')) {
      this.$router.push("/pre");
    }
    if (this.$route.query.cid1 && this.$route.query.cid1 !== "") {
      if (
        this.$route.query.cid1 == 1 ||
        this.$route.query.cid1 == 2 ||
        this.$route.query.cid1 == 3 ||
        this.$route.query.cid1 == 4
      ) {
        this.SET_MENU_TYPE(Number(this.$route.query.cid1));
      }
      if (this.$route.query.cid1 == 5) {
        this.$router.push("/offers");
      }
    }
    this.searchType = this.menuType;
    /**
     * 订阅商品菜单事件
     */
    $event.$on(EVENT_NAME.FOOTER_BANNER_QUERY, () => {
      this.onFooterBannerList().then();
      this.searchType = this.menuType;
    });
    /**
     * 订阅商品菜单事件
     */
    $event.$on(EVENT_NAME.OPEN_LOGON, (name) => {
      this.logonName = name;
      this.logonVisible = true;
    });
    /**
     * 订阅商品菜单事件
     */
    $event.$on(EVENT_NAME.OPEN_LOGON_CLOSE, () => {
      this.logonVisible = false;
    });
    this.onFooterBannerList().then();
  },
  mounted() {
    if (!isPhone()) {
      this.device();
      window.addEventListener("resize", this.device, true);
    }
  },
  methods: {
    ...mapMutations("product", ["SET_MENU_TYPE"]),
    device() {
      let deviceWidth = document.getElementById("layoutBox").offsetWidth;
      if (deviceWidth <= 1210) deviceWidth = 1210;
      if (deviceWidth > 1920) deviceWidth = 1920;
      document.documentElement.style.fontSize = deviceWidth / 12.7 + "px";
      if (this.$route.path === "/product/details") {
        if (
          document.documentElement.scrollTop === 0 &&
          document.body.scrollTop === 0
        ) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "visible";
        }
      }
      $event.$emit(EVENT_NAME.CAROUSEL_OFFSET_WIDTH);
    },
    onRemind() {
      this.remindStatus = false;
      localStorage.setItem("remind", "1");
    },
    /**
     * 选择分类
     * @param id
     */
    onSelectType() {
      this.searchType = this.menuType;
      this.onFooterBannerList().then();
    },
    /**
     * 关闭弹框
     */
    onClose() {
      this.logonVisible = false;
    },
    /**
     * 拉取footer banner列表
     * @returns {Promise<void>}
     */
    async onFooterBannerList() {
      try {
        let params = {
          categoryId: this.searchType,
          type: 3
        };
        const data = await getBannerService(params);
        this.footerBannerList = data[0];
      } catch (error) {
        console.log(error);
      }
    },

    onCloseMask() {
      this.maskVisible = false;
      $event.$emit(EVENT_NAME.MASK_CLICK_CLOSE);
    },
    /**
     * 打开/关闭蒙版
     * @param boolean
     */
    onMask(boolean) {
      this.maskVisible = boolean;
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    $event.$off([
      EVENT_NAME.FOOTER_BANNER_QUERY,
      EVENT_NAME.OPEN_LOGON,
      EVENT_NAME.OPEN_LOGON_CLOSE
    ]);
  }
};
export default IndexPage;
