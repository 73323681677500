/**
 * 公共头部组件
 * @type {{data(): {}}}
 */
import Vue from "vue";
import { Icon, Button as vanButton } from "vant";

Vue.use(Icon);
Vue.component("vanButton", vanButton);
import { mapMutations, mapState } from "vuex";
import { getMenuAllService } from "@/service/system-service/index";
import { getBrandLetterService } from "@/service/goods-brand-service/index";
import { getCountryListService } from "@/service/system-service";
import Cookies from "js-cookie";
import CustomSelect from "@/components/CustomSelect/customSelect.vue";
import {
  currency,
  language,
  LINKS_NAME,
  userMenu,
  SOURCE_NAME,
  likeTipsData,
  subscribeType
} from "@/service/public/enum";
import {
  userLogoutService,
  userSignInService,
  getUserInfoService,
  userRegisterService
} from "@/service/user-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import {
  onCharacterPrice,
  isPhone,
  callBackTop,
  validateEmail,
  validatePassword
} from "@/utils/tools";
import VueLazyLoad from "vue-lazyload";
import {
  getGoodsRemindListService,
  getHistoryListService,
  getMarketSearchFastService,
  getMarketSearchService
} from "@/service/goods-remind-service";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
import History from "@/components/History/history.vue";
import UserCenter from "@/components/UserCenter/userCenter.vue";
import { wbAccess, wxAccess } from "@/utils/share";
const Header = {
  name: "Header",
  components: {
    CustomSelect,
    History,
    UserCenter
  },
  computed: {
    ...mapState({
      userAccount: (state) => state.userCenter.userAccount, //获取状态管理用户信息
      alertNew: (state) => state.userCenter.alertNew, //获取状态管理新的提醒
      langCode: (state) => state.language.code, //获取状态管理语言代码
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      countryCode: (state) => state.system.countryCode, //获取状态管理国家代码
      menuType: (state) => state.product.menuType || localStorage.getItem('cid') //获取状态管理菜单分类
    }),
    onStyle() {
      return (obj) => {
        let color = "";
        if (obj.isSale === 1) {
          color = "color:red;";
        }
        if (obj.categoryId === this.secondType.categoryId) {
          color = "font-family: Montserrat-Bold,FZLT-Bold";
          if (obj.isSale === 1) {
            color = "color:red;font-family: Montserrat-Bold,FZLT-Bold";
          }
        }
        return color;
      };
    },
    onStyleFont() {
      return (obj) => {
        let style = "";
        if (this.selectType === 1 || this.selectType === 2) {
          if (obj.id === 6 || obj.id === 15) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:26px";
            }
          }
          if (obj.id === 7 || obj.id === 16) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:44px";
            }
          }
          if (obj.id === 8 || obj.id === 17) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:54px";
            }
          }
          if (obj.id === 9 || obj.id === 18) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:38px";
            }
          }
          if (obj.id === 10 || obj.id === 19) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:31px";
            }
          }
          if (obj.id === 11 || obj.id === 20) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:73px";
            }
          }
          if (obj.id === 12 || obj.id === 21) {
            if (this.langCode === "CN") {
              style = "width:60px";
            } else {
              style = "width:128px";
            }
          }
          if (obj.id === 13 || obj.id === 22) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:44px";
            }
          }
          if (obj.id === 480 || obj.id === 530) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:45px";
            }
          }
          if (obj.id === 14 || obj.id === 23) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:72px";
            }
          }
        }
        if (this.selectType === 3) {
          if (obj.id === 24) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:26px";
            }
          }
          if (obj.id === 25) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:44px";
            }
          }
          if (obj.id === 26) {
            if (this.langCode === "CN") {
              style = "width:100px";
            } else {
              style = "width:98px";
            }
          }
          if (obj.id === 27) {
            if (this.langCode === "CN") {
              style = "width:84px";
            } else {
              style = "width:85px";
            }
          }
          if (obj.id === 28) {
            if (this.langCode === "CN") {
              style = "width:101px";
            } else {
              style = "width:93px";
            }
          }
          if (obj.id === 531) {
            if (this.langCode === "CN") {
              style = "width:24px";
            } else {
              style = "width:45px";
            }
          }
        }
        return style;
      };
    },
    onSubStyle() {
      return (value) => {
        let style = "";
        if (value === 1) {
          style = "width:50px";
        }
        if (value === 2) {
          style = "width:28px";
        }
        if (value === 3) {
          style = "width:27px";
        }
        if (value === 4) {
          style = "width:38px";
        }
        return style;
      };
    },
    onTypeStyle() {
      return (id) => {
        let style = "";
        if (id === 1) {
          style = "width:80px";
        }
        if (id === 2) {
          style = "width:58px";
        }
        if (id === 3) {
          style = "width:58px";
        }
        if (id === 4) {
          style = "width:68px";
        }
        if (id === 5) {
          style = "width:69px";
        }
        if (this.langCode === "EN" || this.langCode === "") {
          return style;
        } else {
          return "width:auto";
        }
      };
    },
    onIsSale() {
      return (level, secondLevel) => {
        let color = "";
        if (secondLevel.isSale !== 1 && secondLevel.isNew !== 1) {
          if (level.isSale === 1) {
            if (secondLevel.category1[0].name === this.$t("menu.category")) {
              color = "color:#000;";
            } else {
              color = "color:#000;";
            }
          }
        }
        return color;
      };
    },
    onKidsStyle() {
      return (obj) => {
        if (obj.isSale === 1 || obj.isNew === 1) {
          return "kids-1";
        }
        if (obj.id === 26) {
          return "kids-2";
        }
        if (obj.id === 27 || obj.id === 28) {
          return "kids-4";
        }
      };
    }
  },
  data() {
    return {
      logonName: "login",
      isPhone: isPhone,
      onCharacterPrice: onCharacterPrice,
      slider: 0,
      offsetHeight: 270,
      scroll: 0,
      historyVisible: false,
      likeTipsVisible: false,
      searchListVisible: true,
      likeTipsObj: {},
      token: Cookies.get("token"),
      loginVisible: false,
      loadingStatus: false,
      accountStatus: false,
      passwordVisible: false,
      accountPrintVisible: false,
      account: "",
      password: "",
      accountPrint: "",
      likeTime: "", //定时器对象
      searchTime: "", //定时器对象
      linksName: LINKS_NAME, //跳转的名字
      currency: currency, //币种
      language: language, //语言
      userMenu: userMenu, //个人中心
      subscribeType: subscribeType, //订阅分类数据
      country: [], //国家
      isSearch: false, //国家搜索框
      displayCountry: [], // 搜索展示国家
      selectLanguage: language[1], //选中的语言
      selectCurrency: currency[1], //选中的币种
      selectCountry: {}, //选中的国家
      languageVisible: false, //语言弹框可显示
      currencyVisible: false, //币种弹框可显示
      countryVisible: false, //国家弹框可显示
      userCenterVisible: false, //用户中心弹框可显示
      searchVisible: false, //搜索弹框可显示
      alertVisible: false, //提醒弹框可显示
      selectType: "", //选中的分类
      menuVisible: false,
      tertiaryVisible: false,
      threeVisible: false,
      shareVisible: false,
      searchInput: "", //输入搜索内容
      searchInputTips: "",
      searchInputPlaceholder: this.$t("header.search"),
      searchListInput: [], //搜索列表
      searchEmptyList: [],
      searchRecommendList: [],
      menuObj: [], //菜单对象
      selectMenu: {}, // 已选择的一级菜单
      secondObj: [], //二级菜单对象
      tertiaryObj: {}, //三级菜单对象
      brandLetterObj: [], //首字母品牌列表对象
      historyList: [], //浏览历史数据集
      brandLetterSelectList: [], //选中的首字母对象
      brandLetterSelectLetter: "", //选中的首字母
      marketCategoryObj: {}, //分类广告对象
      newAlertsData: {}, //新提醒数据集
      searchObj: {}, //搜索信息对象
      searchType: 1, //选择分类
      secondType: {
        categoryId: "",
        name: ""
      }
    };
  },
  created() {
    if (this.$route.path === "/offers") {
      this.selectType = 5;
    } else {
      this.selectType = this.menuType;
    }
    this.likeTipsObj =
      likeTipsData[Math.floor(Math.random() * likeTipsData.length)];
    /**
     * 订阅header提示状态事件
     */
    $event.$on(EVENT_NAME.HEADER_TIPS_STATUS, () => {
      this.onTipsTime();
    });
    /**
     * 订阅点击mask蒙版消除弹框事件
     */
    $event.$on(EVENT_NAME.MASK_CLICK_CLOSE, () => {
      this.loginVisible = false;
      this.menuVisible = false;
      this.languageVisible = false;
      this.tertiaryVisible = false;
      this.accountStatus = false;
      this.$emit("on-mask", false);
    });
    /**
     * 订阅点击按钮弹出登录/注册
     */
    $event.$on(EVENT_NAME.LINKS_LOGIN_REGISTER, (name) => {
      this.loginVisible = true;
      this.logonName = name;
      this.$emit("on-mask", true);
      callBackTop();
    });
    /**
     * 更新头部菜单选中分类
     */
    $event.$on(EVENT_NAME.HEADER_MENU_TYPE, (n) => {
      if (n === 5) {
        this.selectType = 5;
      } else {
        this.selectType = this.menuType;
      }
    });
    /**
     * 订阅 登录操作token
     */
    $event.$on(EVENT_NAME.LOGIN_TOKEN, (token) => {
      if (token === "1") {
        this.token = "";
      } else {
        this.token = Cookies.get("token");
        this.onGoodsRemindList().then();
      }
    });
    this.onMenuAll().then();
    this.onCountryList().then(() => {
      if (
        !localStorage.getItem("countryCode") ||
        localStorage.getItem("countryCode") === ""
      ) {
        this.selectCountry = this.country[20].list[2];
      } else {
        for (let i in this.country) {
          for (let j in this.country[i].list) {
            if (
              localStorage.getItem("countryCode") ===
              this.country[i].list[j].countryCode
            ) {
              this.selectCountry = this.country[i].list[j];
              break;
            }
          }
        }
      }
    });
    if (
      !localStorage.getItem("language") ||
      localStorage.getItem("language") === ""
    ) {
      this.selectLanguage = language[1];
    } else {
      for (let i in language) {
        if (localStorage.getItem("language") === language[i].code) {
          this.selectLanguage = language[i];
        }
      }
    }
    if (
      !localStorage.getItem("currency") ||
      localStorage.getItem("currency") === ""
    ) {
      this.selectCurrency = currency[3];
    } else {
      for (let i in currency) {
        if (localStorage.getItem("currency") === currency[i].code) {
          this.selectCurrency = currency[i];
        }
      }
    }
    if (Cookies.get("token")) {
      this.onGoodsRemindList().then();
    }
    /**
     * 点击空白处消除弹框
     */
    document.addEventListener("mouseup", (e) => {
      let thisId = document.getElementById("frameSearchId");
      if (thisId) {
        if (!thisId.contains(e.target)) {
          this.searchVisible = false;
          this.searchListInput = [];
        }
      }
    });
    /**
     * 订阅 查询浏览记录
     */
    $event.$on(EVENT_NAME.HISTORY_QUERY, () => {
      this.onHistoryList().then();
    });
    /**
     * 更新币种
     */
    $event.$on(EVENT_NAME.UPDATE_CURRENCY, () => {
      for (let i in currency) {
        if (currency[i].code === this.currencyCode) {
          this.selectCurrency = currency[i];
        }
      }
    });
    this.onHistoryList().then();
  },
  methods: {
    ...mapMutations("language", ["SET_CODE"]),
    ...mapMutations("system", [
      "SET_CURRENCY_CODE",
      "SET_COUNTRY_CODE",
      "SET_COUNTRY_LIST"
    ]),
    ...mapMutations("userCenter", [
      "SET_SELECT_MENU",
      "SET_USER_ACCOUNT",
      "DEL_ALERT_NEW"
    ]),
    ...mapMutations("product", [
      "SET_LETTER",
      "SET_SOURCE",
      "SET_PRODUCT_ID",
      "SET_NEW_SALE_NAME",
      "SET_LEVEL",
      "SET_LABEL",
      "SET_MENU_ID",
      "SET_BRAND_ID",
      "SET_IS_PARENT",
      "SET_SELECTION_ID",
      "SET_MENU_TYPE",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_LEVEL_TYPE",
      "SET_FIVE_TYPE",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD",
      "SET_LIST_FILTER",
      "SET_BRAND_FILTER",
      "SET_BRAND_DETAIL"
    ]),
    ...mapMutations("search", [
      "SET_SEARCH_EMPTY_LIST",
      "SET_SEARCH_RECOMMEND_LIST"
    ]),
    /**
     * 监听超卖滚动条
     */
    carouselScroll(e) {
      this.scroll = Math.ceil(e.srcElement.scrollTop);
      let height = this.country.length * 29 - this.offsetHeight;
      this.slider = ((this.scroll / height) * 270) / 49.5;
    },
    onFocus() {
      this.searchInputPlaceholder = "";
    },
    onBlur() {
      this.searchInputPlaceholder = this.$t("header.search");
    },
    showSearch(type) {
      this.isSearch = type;
      if (type) {
        setTimeout(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        }, 0);
      }
    },
    //  国家搜索
    changeSearch(e) {
      this.onCountryList().then(() => {
        this.country.forEach((el, index) => {
          const res = el.list.filter((c) => {
            return (
              c.countryCname
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) !== -1 ||
              c.countryEname
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) !== -1
            );
          });
          this.displayCountry[index].list = res;
        });
        this.displayCountry = this.displayCountry.filter((el) => {
          return el.list.length
        })
      });
    },
    /**
     * 回车搜索
     */
    onMarketSearchList() {
      if (this.searchListInput.length !== 0) {
        this.SET_LABEL(this.searchListInput[0].name);
        this.SET_MENU_TYPE(this.searchType);
        this.selectType = this.searchType;
        this.SET_MENU_ID(this.searchType);
        this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        this.searchVisible = false;
        if (this.searchListInput[0].type === 1) {
          //品牌
          this.SET_BRAND_ID(this.searchListInput[0].id);
          this.SET_IS_NEW("");
          this.SET_IS_SALE("");
          this.SET_IS_OLD("");
          this.SET_LEVEL("");
          if (this.$route.path === "/brands/list") {
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY);
          } else {
            this.$router.push("/brands/list");
          }
        }
        if (this.searchListInput[0].type === 2) {
          //分类
          if (this.$route.path === "/product/list") {
            $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
            $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
          } else {
            this.$router.push(
              "/product/list?label=" +
                this.searchListInput[0].name +
                "&menuType=" +
                this.searchType +
                "&menuId=" +
                this.searchType +
                "&source=" +
                SOURCE_NAME.MENU_LINK_LIST
            );
          }
        }

        this.$emit("on-mask", false);
      }
    },
    /**
     * 跳转列表页面
     * @param list
     */
    onSearchLink(list) {
      this.SET_LABEL(list.name);
      this.SET_MENU_TYPE(this.searchType);
      this.selectType = this.searchType;
      this.SET_MENU_ID(this.searchType);
      this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
      this.searchVisible = false;
      if (list.type === 1) {
        //品牌
        this.SET_BRAND_ID(list.id);
        this.SET_IS_NEW("");
        this.SET_IS_SALE("");
        this.SET_IS_OLD("");
        this.SET_LEVEL("");
        if (this.$route.path === "/brands/list") {
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY);
        } else {
          this.$router.push("/brands/list");
        }
      }
      if (list.type === 2) {
        //分类
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
        } else {
          this.$router.push(
            "/product/list?label=" +
              list.name +
              "&menuType=" +
              this.searchType +
              "&menuId=" +
              this.searchType +
              "&source=" +
              SOURCE_NAME.MENU_LINK_LIST
          );
        }
      }
      if (this.isPhone()) {
        this.$emit("on-mask", false);
      }
    },
    /**
     * 跳转列表页
     * @param obj
     */
    onProductList(obj, brand) {
      this.SET_LABEL(obj.coverTitle);
      this.SET_SELECTION_ID(obj.selectionId);
      this.SET_MENU_TYPE(this.searchType);
      this.selectType = this.searchType;
      this.SET_MENU_ID(this.searchType);
      this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
      this.SET_SECOND_TYPE({
        categoryId: "",
        name: ""
      });
      this.SET_TERTIARY_TYPE({
        categoryId: "",
        name: ""
      });
      this.searchVisible = false;
      if (brand === "brand") {
        this.SET_BRAND_ID(obj.brandId);
        this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        this.SET_IS_NEW("");
        this.SET_IS_SALE("");
        this.SET_IS_OLD("");
        this.SET_LEVEL("");
        if (this.$route.path === "/brands/list") {
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY);
        } else {
          this.$router.push("/brands/list");
        }
      } else {
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
        } else {
          this.$router.push(
            "/product/list?label=" +
              obj.coverTitle +
              "&selectionId" +
              obj.selectionId +
              "&menuType=" +
              this.searchType +
              "&menuId=" +
              this.searchType +
              "&source=" +
              SOURCE_NAME.SUPER_SALE +
              '&secondId=""&secondName=""&tertiaryId=""&tertiaryName=""'
          );
        }
      }

      this.$emit("on-mask", false);
    },
    /**
     * 关闭弹框
     */
    onClose() {
      if (this.isPhone()) {
        this.menuVisible = false;
        this.languageVisible = false;
        this.currencyVisible = false;
        this.countryVisible = false;
        this.loginVisible = false;
        this.tertiaryVisible = false;
        this.accountStatus = false;
        this.$emit("on-mask", false);
      } else {
        this.historyVisible = false;
      }
    },
    /**
     * 选择分类
     * @param value
     */
    onSearchLabelCheck(value) {
      this.searchType = value;
      this.onMarketSearchFast().then();
    },
    /**
     * 查看浏览记录
     */
    onHistory() {
      this.onHistoryList().then();
      this.historyVisible = true;
      $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE);
    },
    /**
     * 查询搜索信息
     */
    onMarketFast() {
      if (this.isPhone()) {
        this.$emit("on-mask", true);
      }
      this.searchInput = "";
      this.searchVisible = true;
      this.onMarketSearchFast().then();
    },
    /**
     * 跳转到详情页
     * @param id
     */
    onQueryDetails(id) {
      this.onClose();
      this.SET_PRODUCT_ID(id);
      this.DEL_ALERT_NEW(id);
      $event.$emit(EVENT_NAME.LOGIN_TOKEN);
      if (this.$route.path === "/product/details") {
        $event.$emit(EVENT_NAME.PRODUCT_GROUP_DETAILS, id);
      } else {
        this.$router.push("/product/details?id=" + id);
      }
    },
    /**
     * 跳转
     * @param obj
     */
    onOffersLink(obj) {
      if (obj.contentType === 1) {
        window.open(obj.link, "_blank");
      } else {
        if (JSON.stringify(obj) === "{}") {
          return;
        }
        this.SET_SELECTION_ID(obj.selectionId);
        this.SET_MENU_ID(obj.id);
        this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
        this.SET_SECOND_TYPE({
          categoryId: "",
          name: ""
        });
        this.SET_TERTIARY_TYPE({
          categoryId: "",
          name: ""
        });
        this.$router.push("/product/list");
      }
    },
    /**
     * 点击关闭
     */
    onClear() {
      this.likeTipsVisible = false;
      clearTimeout(this.likeTime);
    },
    onTipsTime() {
      if (!this.likeTipsVisible) {
        this.likeTipsObj =
          likeTipsData[Math.floor(Math.random() * likeTipsData.length)];
        this.likeTipsVisible = true;
        this.likeTime = setTimeout(() => {
          this.likeTipsVisible = false;
        }, 10000);
      }
    },
    onMouseoverType(obj) {
      this.selectType = obj.id;
      this.menuVisible = true;
      if (obj.id === 4 || obj.id === 5) {
        this.threeVisible = true;
      } else {
        this.secondType.categoryId = obj.menu1[0].categoryId;
        this.secondType.name = obj.menu1[0].name;
        this.threeVisible = false;
      }
    },
    onMouseoutType() {
      if (this.$route.path === "/offers") {
        this.selectType = 5;
      } else {
        this.selectType = this.menuType;
      }
    },
    /**
     * 分享配置
     * @param config
     * @param obj
     */
    onShare(config, obj) {
      switch (config) {
        case "wb":
          wbAccess(obj.source, obj.logoImage, obj.link);
          break;
        case "wx":
          wxAccess("");
          break;
      }
    },
    /**
     * 登录方法
     * @returns {Promise<void>}
     */
    async onSignIn() {
      try {
        let params = {
          username: this.account,
          password: this.password
        };
        const { code, token } = await userSignInService(params);
        if (token) {
          Cookies.set("token", token, { expires: 1 });
          this.onUserInfo().then();
        }
        if (code === 1001) {
          this.accountPrintVisible = true;
          this.accountPrint = this.$t("login.passwordInvalidTips");
        }
        if (code === 1002) {
          this.accountPrintVisible = true;
          this.accountPrint = this.$t("login.accountTips");
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 获取用户信息
     * @returns {Promise<void>}
     */
    async onUserInfo() {
      try {
        const { user } = await getUserInfoService({});
        this.SET_USER_ACCOUNT(user.email);
        this.$router.push("/").then();
        location.reload();
        this.loadingStatus = false;
      } catch (error) {
        console.log(error);
        this.loadingStatus = false;
      }
    },
    /**
     * 注册
     * @returns {Promise<void>}
     */
    async onRegister() {
      if (this.isPhone()) {
        try {
          let params = {
            email: this.account,
            password: this.password
          };
          const { code } = await userRegisterService(params);
          if (code === 1) {
            this.logonName = "login";
            this.accountPrintVisible = true;
            this.accountPrint = this.$t("login.registerSuccessTips");
          }
          if (code === 1003) {
            this.accountPrintVisible = true;
            this.accountPrint = this.$t("login.registeredPrompt");
          }
          this.loadingStatus = false;
        } catch (error) {
          this.loadingStatus = false;
          console.log(error);
        }
      } else {
        this.likeTipsVisible = false;
        $event.$emit(EVENT_NAME.OPEN_LOGON, "Register");
      }
    },
    /**
     * 登录
     */
    onLogin() {
      if (this.isPhone()) {
        if (!Cookies.get("token")) {
          this.loginVisible = !this.loginVisible;
          this.$emit("on-mask", this.loginVisible);
          return;
        }
        this.accountStatus = true;
        this.$emit("on-mask", true);
        this.SET_SELECT_MENU(LINKS_NAME.USER_MENU);
      } else {
        this.likeTipsVisible = false;
        $event.$emit(EVENT_NAME.OPEN_LOGON, "SignIn");
      }
    },
    /**
     *  校验方法 目前只校验账户密码是否为空 通过之后调用登录方法
     *  @param account && password
     */
    onSignInCheck() {
      if (!validateEmail(this.account)) {
        this.accountPrintVisible = true;
        this.accountPrint = this.$t("login.emailTips");
        return;
      }
      if (this.password === "") {
        this.accountPrintVisible = true;
        this.accountPrint = this.$t("login.keyPasswordTips");
        return;
      }
      this.loadingStatus = true;
      this.onSignIn().then();
    },
    /**
     *  校验方法 目前只校验账户密码是否为空 通过之后开启防抖函数 调用登录方法
     *  @param account && password
     */
    onRegisterInCheck() {
      if (!validateEmail(this.account)) {
        this.accountPrintVisible = true;
        this.accountPrint = this.$t("login.emailTips");
        return;
      }
      if (!validatePassword(this.password)) {
        this.accountPrintVisible = true;
        this.accountPrint = this.$t("login.passwordTips");
        return;
      }
      this.loadingStatus = true;
      this.onRegister().then();
    },
    /**
     * 切换选择框
     * @param name
     */
    onSwitchLogon(name) {
      this.logonName = name;
    },
    /**
     * 密码可显示
     */
    onPasswordShow() {
      this.passwordVisible
        ? (this.passwordVisible = false)
        : (this.passwordVisible = true);
    },
    /**
     * 跳转登录
     * @param name
     */
    onLinkLogin(name) {
      if (name === "login") {
        this.logonName = "login";
      }
      if (name === "create") {
        this.logonName = "create";
      }
      this.loginVisible = true;
      this.menuVisible = false;
      callBackTop();
    },
    /**
     * 跳转品牌列表
     */
    onBrandList(type1, type2, name, brandLetter) {
      this.onClose();
      this.menuVisible = false;
      this.selectType = type1.categoryId;
      this.SET_MENU_TYPE(type1.categoryId);
      this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
      if (name === "menu2" || type2.isSale === 1 || type2.isNew === 1) {
        this.SET_MENU_ID(type1.categoryId);
      }
      if (name === "menu3" && type2.isSale !== 1 && type2.isNew !== 1) {
        this.SET_MENU_ID(type2.categoryId);
      }
      if (brandLetter) {
        this.SET_LETTER(brandLetter.letter);
      } else {
        this.SET_LETTER("");
      }
      if (this.$route.path === "/brands") {
        $event.$emit(EVENT_NAME.PRODUCT_BRANDS_QUERY);
      } else {
        this.$router.push("/brands");
      }
    },
    /**
     * 展开语言弹框
     */
    onLanguageShow() {
      this.menuVisible = false;
      this.languageVisible = true;
      callBackTop();
    },
    /**
     * 关闭语言弹框
     */
    onLanguageClose() {
      this.menuVisible = true;
      this.languageVisible = false;
    },
    /**
     * 展开国家弹框
     */
    onCountryShow() {
      this.menuVisible = false;
      this.countryVisible = true;
      callBackTop();
    },
    /**
     * 关闭国家弹框
     */
    onCountryClose() {
      this.menuVisible = true;
      this.countryVisible = false;
    },
    /**
     * 展开货币弹框
     */
    onCurrencyShow() {
      this.menuVisible = false;
      this.currencyVisible = true;
      callBackTop();
    },
    /**
     * 关闭货币弹框
     */
    onCurrencyClose() {
      this.menuVisible = true;
      this.currencyVisible = false;
    },
    /**
     * 关闭三级弹框
     */
    onTertiaryClose() {
      this.menuVisible = true;
      this.tertiaryVisible = false;
    },
    /**
     * 展开菜单
     */
    onMenuShow() {
      this.menuVisible = true;
      for (let i in this.menuObj) {
        const menu = this.menuObj[i];
        const menu1 = menu.menu1;

        if (this.isPhone() && menu1) {
          let brandIndex = menu1.findIndex(
            (e) => e.name === "Brands" || e.name === "品牌"
          );
          const brand = menu1.splice(brandIndex, 1)[0];
          menu1.splice(2, 0, brand);
        }

        if (this.menuType === menu.id && (menu.id !== 4 || !this.isPhone())) {
          this.secondObj = menu1;
          this.selectMenu = menu;
        } else if (
          this.menuType === menu.id &&
          menu.id === 4 &&
          this.isPhone()
        ) {
          this.secondObj = [...menu1[0].category1, ...menu1[0].category2];
          this.secondObj.splice(0, 1);
          let allIndex = this.secondObj.findIndex(
            (e) => e.name === "View all" || e.name === "全部"
          );
          this.secondObj.splice(allIndex, 1);
          this.secondObj.splice(
            this.secondObj.findIndex(
              (e) => e.name === "Space" || e.name === "空间"
            ),
            1
          );
          const brand = {
            isBrand: 1,
            name: this.$t("brand.brands")
          };
          this.secondObj.splice(2, 0, brand);
          this.selectMenu = menu;
          this.selectSecondList = menu1[0]
        }
      }
      this.$emit("on-mask", true);
    },
    /**
     * 点击二级跳转list
     * @param obj
     */
    onSecondList(obj, id) {
      this.SET_LIST_FILTER(false);
      this.SET_BRAND_FILTER(false);
      if (this.isPhone()) {
        this.menuVisible = false;
        this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        this.SET_LEVEL(obj.level);
        this.SET_MENU_ID(obj.categoryId);
        this.SET_LABEL(obj.name);
        this.SET_IS_NEW(obj.isNew);
        this.SET_IS_SALE(obj.isSale);
        if (obj.name === this.$t("menu.isOld")) {
          this.SET_IS_OLD(1);
        } else {
          this.SET_IS_OLD(0);
        }
        this.SET_NEW_SALE_NAME("");
        if (obj.isBrand === 1) {
          this.$emit("on-mask", false);
          if (this.$route.path === "/brands") {
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_QUERY);
          } else {
            this.$router.push("/brands");
          }
          return;
        }
        this.tertiaryVisible = true;
        this.tertiaryObj = [];
        this.selectSecondList = obj;
        this.SET_SECOND_TYPE({
          categoryId: obj.categoryId,
          name: obj.name
        });
        this.SET_TERTIARY_TYPE({
          categoryId: "",
          name: ""
        });
        if (obj.category1) {
          this.tertiaryObj.push({
            name: obj.name,
            list: obj.category1.slice(1, obj.category1.length)
          });
        }
        if (obj.category2) {
          this.tertiaryObj.push({
            name: obj.category2[0].name,
            list: obj.category2.slice(1, obj.category2.length)
          });
        }
        if (obj.category3) {
          this.tertiaryObj.push({
            name: obj.category3[0].name,
            list: obj.category3.slice(1, obj.category3.length)
          });
        }
        if (obj.category4) {
          this.tertiaryObj.push({
            name: obj.category4[0].name,
            list: obj.category4.slice(1, obj.category4.length)
          });
        }
        if (obj.category5) {
          this.tertiaryObj.push({
            name: obj.category5[0].name,
            list: obj.category5.slice(1, obj.category5.length)
          });
        }
        if (obj.category6) {
          this.tertiaryObj.push({
            name: obj.category6[0].name,
            list: obj.category6.slice(1, obj.category6.length)
          });
        }
        if (!this.isPhone()) {
          if (obj.brands && obj.brands.length !== 0) {
            this.tertiaryObj.push({
              name: this.$t("menu.brands"),
              list: obj.brands
            });
          }
        }
      } else {
        this.menuVisible = false;
        this.SET_MENU_TYPE(id);
        this.selectType = this.menuType;
        this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        this.SET_LEVEL(obj.level);
        this.SET_MENU_ID(obj.categoryId);
        this.SET_LABEL(obj.name);
        this.SET_IS_NEW(obj.isNew);
        this.SET_IS_SALE(obj.isSale);
        if (obj.name === this.$t("menu.isOld")) {
          this.SET_IS_OLD(1);
        } else {
          this.SET_IS_OLD(0);
        }
        this.SET_NEW_SALE_NAME("");
        this.$emit("on-select-type");
        if (obj.isBrand === 1) {
          if (this.$route.path === "/brands") {
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_QUERY);
          } else {
            this.$router.push("/brands");
          }
          return;
        }
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY, obj);
        } else {
          this.$router.push("/product/list");
        }
      }
    },
    /**
     * 调用搜索
     */
    onSearchInput() {
      this.onMarketSearch().then();
    },
    /**
     * 点击三级跳转list
     * @param obj
     */
    onTertiaryList(tertiaryType, secondType, id, brands, name, kids) {
      this.onClose();
      this.SET_BRAND_DETAIL(tertiaryType);
      $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE);
      this.menuVisible = false;
      this.selectType = this.menuType;
      this.SET_MENU_TYPE(id);
      let menuType = id;
      let secondId = "";
      let secondName = "";
      let tertiaryId = "";
      let tertiaryName = "";
      let levelId = "";
      let levelName = "";
      let isNew = "";
      let isSale = "";
      let isOld = 0;
      let isParent = "";
      let source = "";
      let label = "";
      let menuId = "";
      let level = 0;
      let newSaleName = "";
      if (tertiaryType.name === this.$t("header.viewAll")) {
        this.SET_SECOND_TYPE({
          categoryId: tertiaryType.categoryId,
          name: tertiaryType.name
        });
        if (secondType.isSale === 1 || secondType.isNew === 1) {
          secondId = "";
          secondName = "";
        } else {
          secondId = tertiaryType.categoryId;
          secondName = tertiaryType.name;
        }
        this.SET_TERTIARY_TYPE({
          categoryId: "",
          name: ""
        });
        tertiaryId = "";
        tertiaryName = "";
        this.SET_LEVEL_TYPE({
          categoryId: "",
          name: ""
        });
        levelId = "";
        levelName = "";
        this.SET_LABEL(secondType.name);
      } else {
        this.SET_SECOND_TYPE({
          categoryId: secondType.id,
          name: secondType.name
        });
        if (secondType.isSale === 1 || secondType.isNew === 1) {
          secondId = tertiaryType.categoryId;
          secondName = tertiaryType.name;
          tertiaryId = "";
          tertiaryName = "";
        } else {
          secondId = secondType.id;
          secondName = secondType.name;
          tertiaryId = tertiaryType.categoryId;
          tertiaryName = tertiaryType.name;
        }

        this.SET_TERTIARY_TYPE({
          categoryId: tertiaryType.categoryId,
          name: tertiaryType.name
        });
        this.SET_LEVEL_TYPE({
          categoryId: "",
          name: ""
        });
        levelId = "";
        levelName = "";
      }
      if (id === 4) {
        this.SET_IS_NEW(tertiaryType.isNew);
        this.SET_IS_SALE(tertiaryType.isSale);
        isNew = tertiaryType.isNew;
        isSale = tertiaryType.isSale;
      } else {
        this.SET_IS_NEW(secondType.isNew);
        this.SET_IS_SALE(secondType.isSale);
        isNew = secondType.isNew;
        isSale = secondType.isSale;
      }
      if (secondType.name === this.$t("menu.isOld")) {
        this.SET_IS_OLD(1);
        isOld = 1;
      } else {
        this.SET_IS_OLD(0);
        isOld = 0;
      }
      this.SET_IS_PARENT(tertiaryType.isParent);
      isParent = tertiaryType.isParent;
      this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
      source = SOURCE_NAME.MENU_LINK_LIST;
      if (brands === 1) {
        this.SET_LABEL(tertiaryType.name);
        label = tertiaryType.name;
        this.SET_BRAND_ID(tertiaryType.id);
        if (secondType.isSale === 1 || secondType.isNew === 1) {
          this.SET_MENU_ID(id);
          this.SET_LEVEL(1);
          menuId = id;
          level = 1;
        } else {
          this.SET_MENU_ID(secondType.categoryId);
          this.SET_LEVEL(2);
          menuId = secondType.categoryId;
          level = 2;
        }
      } else {
        this.SET_MENU_ID(tertiaryType.categoryId);
        this.SET_LEVEL(tertiaryType.level);
        menuId = tertiaryType.categoryId;
        level = tertiaryType.level;
      }
      if (
        (tertiaryType.name === this.$t("header.viewAll") ||
          tertiaryType.isParent === 0) &&
        brands !== 1
      ) {
        if (name !== this.$t("menu.category")) {
          if (id === 3) {
            if (secondType.isSale === 1 || secondType.isNew === 1) {
              this.SET_LABEL(secondType.name + " - " + name);
              label = secondType.name + " - " + name;
            } else {
              if (tertiaryType.grop === 7 || tertiaryType.grop === 8) {
                this.SET_LABEL(name);
                label = name;
              } else {
                this.SET_LABEL(name + " - " + this.$t("menu.clothing"));
                label = name + " - " + this.$t("menu.clothing");
              }
            }
          } else {
            if (name) {
              this.SET_LABEL(name);
              label = name;
            } else {
              this.SET_LABEL(secondType.name);
              label = secondType.name;
            }
          }
        } else {
          this.SET_LABEL(secondType.name);
          label = secondType.name;
        }
        this.SET_NEW_SALE_NAME("");
        newSaleName = "";
      } else {
        if (secondType.isSale === 1 || secondType.isNew === 1) {
          if (id === 3) {
            if (tertiaryType.id === 350 || tertiaryType.id === 382) {
              this.SET_LABEL(secondType.name + " - " + tertiaryType.name);
              label = secondType.name + " - " + tertiaryType.name;
            } else {
              this.SET_LABEL(
                secondType.name + " - " + name + " - " + tertiaryType.name
              );
              label =
                secondType.name + " - " + name + " - " + tertiaryType.name;
            }
          } else {
            this.SET_LABEL(secondType.name + " - " + tertiaryType.name);
            label = secondType.name + " - " + tertiaryType.name;
          }
        } else {
          if (
            (tertiaryType.isSale === 1 || tertiaryType.isNew === 1) &&
            id !== 4
          ) {
            this.SET_NEW_SALE_NAME(tertiaryType.name);
            newSaleName = tertiaryType.name;
            if (name !== this.$t("menu.category")) {
              if (kids === 1) {
                this.SET_LABEL(
                  secondType.category1[0].name +
                    " - " +
                    secondType.category2[3].name
                );
                label =
                  secondType.category1[0].name +
                  " - " +
                  secondType.category2[3].name;
              } else if (kids === 2) {
                this.SET_LABEL(
                  secondType.category1[0].name +
                    " - " +
                    secondType.category3[3].name
                );
                label =
                  secondType.category1[0].name +
                  " - " +
                  secondType.category3[3].name;
              } else if (kids === 3) {
                this.SET_LABEL(
                  secondType.category4[0].name +
                    " - " +
                    secondType.category5[3].name
                );
                label =
                  secondType.category4[0].name +
                  " - " +
                  secondType.category5[3].name;
              } else if (kids === 4) {
                this.SET_LABEL(
                  secondType.category4[0].name +
                    " - " +
                    secondType.category6[3].name
                );
                label =
                  secondType.category4[0].name +
                  " - " +
                  secondType.category6[3].name;
              } else {
                if (
                  (tertiaryType.isSale === 1 || tertiaryType.isNew === 1) &&
                  tertiaryType.grop !== 7 &&
                  tertiaryType.grop !== 8
                ) {
                  this.SET_LABEL(name + " - " + this.$t("menu.clothing"));
                  label = name + " - " + this.$t("menu.clothing");
                } else {
                  this.SET_LABEL(name);
                  label = name;
                }
              }
            } else {
              this.SET_LABEL(secondType.name);
              label = secondType.name;
              if (tertiaryType.isSale === 1) {
                this.SET_IS_SALE("1");
                isSale = 1;
              }
              if (tertiaryType.isNew === 1) {
                this.SET_IS_NEW("1");
                isNew = 1;
              }
              this.SET_TERTIARY_TYPE({
                categoryId: "",
                name: ""
              });
              tertiaryId = "";
              tertiaryName = "";
            }
          } else {
            this.SET_NEW_SALE_NAME("");
            newSaleName = "";
            if (kids === 1) {
              this.SET_LABEL(
                secondType.category1[0].name +
                  " - " +
                  secondType.category2[3].name
              );
              label =
                secondType.category1[0].name +
                " - " +
                secondType.category2[3].name;
            } else if (kids === 2) {
              this.SET_LABEL(
                secondType.category1[0].name +
                  " - " +
                  secondType.category3[3].name
              );
              label =
                secondType.category1[0].name +
                " - " +
                secondType.category3[3].name;
            } else if (kids === 3) {
              this.SET_LABEL(
                secondType.category4[0].name +
                  " - " +
                  secondType.category5[3].name
              );
              label =
                secondType.category4[0].name +
                " - " +
                secondType.category5[3].name;
            } else if (kids === 4) {
              this.SET_LABEL(
                secondType.category4[0].name +
                  " - " +
                  secondType.category6[3].name
              );
              label =
                secondType.category4[0].name +
                " - " +
                secondType.category6[3].name;
            } else {
              this.SET_LABEL(tertiaryType.name);
              label = tertiaryType.name;
            }
          }
        }
      }
      this.$emit("on-select-type");

      if (brands === 1) {
        if (this.$route.path === "/brands/list") {
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY, tertiaryType);
        } else {
          this.$router.push("/brands/list");
        }
      } else {
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY, tertiaryType);
          this.$router.push(
            "/product/list?menuType=" +
              menuType +
              "&secondId=" +
              secondId +
              "&secondName=" +
              secondName +
              "&tertiaryId=" +
              tertiaryId +
              "&tertiaryName=" +
              tertiaryName +
              "&levelId=" +
              levelId +
              "&levelName=" +
              levelName +
              "&isNew=" +
              isNew +
              "&isSale=" +
              isSale +
              "&isOld=" +
              isOld +
              "&isParent=" +
              isParent +
              "&source=" +
              source +
              "&label=" +
              label +
              "&menuId=" +
              menuId +
              "&level=" +
              level +
              "&newSaleName" +
              newSaleName +
              "&size=" +
              70 +
              "&page=" +
              1
          );
        } else {
          this.$router.push(
            "/product/list?menuType=" +
              menuType +
              "&secondId=" +
              secondId +
              "&secondName=" +
              secondName +
              "&tertiaryId=" +
              tertiaryId +
              "&tertiaryName=" +
              tertiaryName +
              "&levelId=" +
              levelId +
              "&levelName=" +
              levelName +
              "&isNew=" +
              isNew +
              "&isSale=" +
              isSale +
              "&isOld=" +
              isOld +
              "&isParent=" +
              isParent +
              "&source=" +
              source +
              "&label=" +
              label +
              "&menuId=" +
              menuId +
              "&level=" +
              level +
              "&newSaleName" +
              newSaleName +
              "&size=" +
              70 +
              "&page=" +
              1
          );
        }
      }
    },
    onAlert() {
      this.SET_SELECT_MENU(LINKS_NAME.SEARCH_REMIND_SHOPPING_LIST);
      this.$router.push("/user/center?wish=1").then();
      this.userCenterVisible = false;
    },
    /**
     * 跳转
     */
    onLinks(name) {
      if (!this.isPhone()) {
        this.onClose();
        $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE);
      }
      switch (name) {
        case LINKS_NAME.LINKS_HOME_PAGE:
          if (this.isPhone()) {
            this.SET_MENU_TYPE("");
            this.secondObj = [];
            this.selectMenu = {};
          }
          this.$emit("on-select-type");
          if (localStorage.getItem("cid")) {
            this.SET_MENU_TYPE(JSON.parse(localStorage.getItem("cid")));
            this.selectType = JSON.parse(localStorage.getItem("cid"));
            if (this.$route.path === "/home/page") {
              $event.$emit(EVENT_NAME.HOME_PAGE_CLASSIFY);
            } else {
              this.$router.push("/home/page");
            }
          } else {
            this.$router.push("/pre");
          }
          break;
        case LINKS_NAME.LINKS_LOGIN:
          this.likeTipsVisible = false;
          $event.$emit(EVENT_NAME.OPEN_LOGON, "SignIn");
          break;
        case LINKS_NAME.ACCOUNT_INFO:
          this.SET_SELECT_MENU(LINKS_NAME.ACCOUNT_INFO);
          this.$router.push("/user/center").then();
          this.userCenterVisible = false;
          break;
        case LINKS_NAME.SEARCH_REMIND_SHOPPING_LIST:
          this.SET_SELECT_MENU(LINKS_NAME.SEARCH_REMIND_SHOPPING_LIST);
          this.$router.push("/user/center").then();
          this.userCenterVisible = false;
          break;
        case LINKS_NAME.USER_PREFERENCES:
          this.SET_SELECT_MENU(LINKS_NAME.USER_PREFERENCES);
          this.$router.push("/user/center").then();
          this.userCenterVisible = false;
          break;
        case LINKS_NAME.USER_LOGOUT:
          this.userCenterVisible = false;
          this.onLogout().then();
          break;
      }
      callBackTop();
    },
    /**
     * 选择语言
     * @param obj
     */
    onLanguage(obj) {
      this.selectLanguage = obj;
      this.languageVisible = false;
      this.SET_CODE(obj.code);
      localStorage.setItem("language", obj.code);
      location.reload();
    },
    /**
     * 选择币种
     * @param obj
     */
    onCurrency(obj) {
      this.selectCurrency = obj;
      this.currencyVisible = false;
      this.SET_CURRENCY_CODE(obj.code);
      localStorage.setItem("currency", obj.code);
      location.reload();
    },
    /**
     * 选择国家
     * @param obj
     */
    onCountry(obj) {
      this.selectCountry = obj;
      this.countryVisible = false;
      this.SET_COUNTRY_CODE(obj.countryCode);
      localStorage.setItem("countryCode", obj.countryCode);
      location.reload();
    },
    /**
     * 选中首字母对象
     * @param obj
     */
    onBrandLetterSelect(obj) {
      this.brandLetterSelectList = obj.list;
      this.brandLetterSelectLetter = obj.letter;
    },
    /**
     * 选中的一级菜单
     * @param id
     */
    onSelectType(id) {
      if (!this.isPhone()) {
        this.onClose();
        $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE);
      }

      this.menuVisible = false;
      this.$emit("on-mask", false);
      this.SET_MENU_TYPE(id);
      this.$emit("on-select-type");
      if (id === 5) {
        this.selectType = 5;
        this.$router.push("/offers");
        return;
      }
      this.selectType = this.menuType;
      this.$route.path === "/home/page"
        ? $event.$emit(EVENT_NAME.HOME_PAGE_CLASSIFY)
        : this.$router.push("/home/page");

      if (this.isPhone()) {
        // this.onMenuShow();
        callBackTop();
      }
    },
    /**
     * 点击more跳转特惠列表页面
     */
    onLinksOffers() {
      this.selectType = 5;
      this.menuVisible = false;
      this.$router.push("/offers");
    },
    onTertiarySelectType(id) {
      this.onSelectType(id);
      this.tertiaryVisible = false;
      this.menuVisible = true;
    },
    /**
     * 选择二级商品分类
     */
    onClassifySecondLevel(obj, classify) {
      this.selectType = classify.id;
      this.secondType.categoryId = obj.categoryId;
      this.secondType.name = obj.name;
      if (obj.isBrand === 1) {
        this.onBrandLetter(classify.id).then();
      }
    },
    onClassifyThree(classify) {
      this.selectType = classify.id;
    },
    /**
     * 广告跳转
     */
    onMarketLink(secondType, id) {
      if (secondType.contentType === 1) {
        location.href = secondType.link;
      } else {
        this.SET_LABEL(secondType.coverTitle);
        this.SET_MENU_TYPE(id);
        this.SET_SOURCE(SOURCE_NAME.MENU_ADVERT);
        this.SET_SELECTION_ID(secondType.selectionId);
        if (secondType.isSale === 1 || secondType.isNew === 1) {
          this.SET_MENU_ID(id);
          this.SET_LEVEL(1);
        } else {
          this.SET_MENU_ID(secondType.categoryId);
          this.SET_LEVEL(2);
        }
        this.menuVisible = false;
        this.$emit("on-select-type");
        if (this.$route.path === "/brands/list") {
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY, secondType);
        } else {
          this.$router.push("/brands/list");
        }
      }
    },
    /**
     * 拉取系统菜单
     * @returns {Promise<void>}
     */
    async onMenuAll() {
      try {
        const data = await getMenuAllService();
        this.menuObj = data;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 拉取首字母品牌列表
     * @returns {Promise<void>}
     */
    async onBrandLetter(menuId) {
      try {
        let params = {
          menuId: menuId
        };
        const data = await getBrandLetterService(params);
        this.brandLetterObj = data;
        this.brandLetterSelectList = data[0].list;
        this.brandLetterSelectLetter = data[0].letter;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 拉取国家列表
     * @returns {Promise<void>}
     */
    async onCountryList() {
      try {
        const { code, data } = await getCountryListService();
        if (code === 1) {
          this.country = data;
          this.displayCountry = new Array(...data);
          this.SET_COUNTRY_LIST(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 退出登录
     * @returns {Promise<void>}
     */
    async onLogout() {
      let confirm = window.confirm(this.$t("login.logoutConfirm"));
      if (confirm) {
        try {
          const { code } = await userLogoutService({});
          if (code === 1) {
            Cookies.remove("token");
            this.token = Cookies.remove("token");
            this.SET_USER_ACCOUNT("");
            if (this.$route.path === "/user/center") {
              this.$router.push("/home/page");
            }
            location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    /**
     * 查询用户商品收藏列表
     * @returns {Promise<void>}
     */
    async onGoodsRemindList() {
      try {
        let string = "";
        for (let i in this.alertNew) {
          string += this.alertNew[i] + ",";
        }
        let params = {
          productIds: string,
          cid1: "",
          page: 1,
          size: 9999
        };
        const { code, data } = await getGoodsRemindListService(params);
        if (code === 1) {
          this.newAlertsData = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询浏览记录列表
     * @returns {Promise<void>}
     */
    async onHistoryList() {
      try {
        let string = "";
        for (let i in JSON.parse(localStorage.getItem("history"))) {
          string += JSON.parse(localStorage.getItem("history"))[i] + ",";
        }
        let params = {
          productIds: string
        };
        const { code, data } = await getHistoryListService(params);
        if (code === 1) {
          this.historyList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询搜索信息
     * @returns {Promise<void>}
     */
    async onMarketSearchFast() {
      try {
        let params = {
          cid1: this.searchType
        };
        const { code, data } = await getMarketSearchFastService(params);
        if (code === 1) {
          this.searchObj = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询模糊搜索信息
     * @returns {Promise<void>}
     */
    async onMarketSearch() {
      try {
        let params = {
          cid1: this.searchType,
          keyword: this.searchInput
        };
        const { code, data } = await getMarketSearchService(params);
        if (code === 1) {
          this.searchListInput = data.list;
          this.searchInputTips = this.searchInput;
          this.searchInput = "";
          this.SET_SEARCH_EMPTY_LIST(data.emptyList);
          this.SET_SEARCH_RECOMMEND_LIST(data.recommendList);
          if (data.list.length === 0) {
            this.$router.push("/search?content=" + this.searchInputTips);
            this.searchListVisible = true;
            this.searchVisible = false;
            this.$emit("on-mask", false);
          } else {
            this.searchListVisible = false;
            clearTimeout(this.searchTime);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询模糊搜索信息
     * @returns {Promise<void>}
     */
    async onMarketSearchClick() {
      try {
        let params = {
          cid1: this.searchType,
          keyword: this.searchInput
        };
        const { code, data } = await getMarketSearchService(params);
        if (code === 1) {
          this.searchListInput = data.list;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    $event.$off([
      EVENT_NAME.HEADER_TIPS_STATUS,
      EVENT_NAME.LOGIN_TOKEN,
      EVENT_NAME.HEADER_MENU_TYPE,
      EVENT_NAME.UPDATE_CURRENCY
    ]);
  }
};
export default Header;
