/**
 *  我的搜索
 * @type {{}}
 */

const Search = {
    name:'Search',
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    },
}
export default Search
