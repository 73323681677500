<template>
    <div class="layout-call-back" id="back" v-show="callBackShow">
        <a href="javascript:" @click="callBackTop"></a>
    </div>
</template>
<script>
import CallBack from './callBack'
export default CallBack
</script>
<style lang="less">
@import "callBack";
</style>
