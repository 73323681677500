<template>
    <div class="layout-information-search">
        <div class="search-list">
            <ul>
                <li :key="key" v-for="key in 2">
                    <h1 class="title">Coats</h1>
                    <div class="label">
                        <span>Parka coats</span>
                        <span>Capes</span>
                        <span>Size S</span>
                    </div>
                    <a href="javascript:" class="close"></a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Search from './search'
export default Search
</script>
<style lang="less">
@import "search";
</style>
