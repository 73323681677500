import Vue from 'vue'
import { Button } from 'iview';
Vue.component('Button', Button);
import $event, {EVENT_NAME} from "@/utils/event-emitter";
import { validateEmail } from "@/utils/tools";
import { userRetrieveService,userRetrieveCodeService } from "@/service/user-service";
import {mapMutations} from "vuex";
const RetrievePwd = {
    name:'RetrievePwd',
    data() {
        return {
            loadingStatus:false,
            newPasswordVisible:false,
            accountPrintVisible:false,
            account:'',
            newPassword:'',
            code:'',
            accountPrint:'',
            counter:60,
            sendStatus:false,
            accountPlaceholder:this.$t('login.account'),
            verificationCodePlaceholder:this.$t('login.verificationCode'),
            newPasswordPlaceholder:this.$t('login.newPassword'),
        }
    },
    mounted() {
        window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        ...mapMutations('userCenter',['SET_USER_ACCOUNT']),
        onFocus(name){
            if(name === 'account'){
                this.accountPlaceholder = ''
            }
            if(name === 'verificationCode'){
                this.verificationCodePlaceholder = ''
            }
            if(name === 'newPassword'){
                this.newPasswordPlaceholder = ''
            }
        },
        onBlur(name){
            if(name === 'account'){
                this.accountPlaceholder = this.$t('login.account')
            }
            if(name === 'verificationCode'){
                this.verificationCodePlaceholder = this.$t('login.verificationCode')
            }
            if(name === 'newPassword'){
                this.newPasswordPlaceholder = this.$t('login.newPassword')
            }
        },
        /**
         * 密码可显示
         */
        onPasswordShow() {
            this.newPasswordVisible?this.newPasswordVisible = false:this.newPasswordVisible = true
        },
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onRetrieveCheck()
            }
        },
        /**
         * 计数器
         */
        onCounter() {
            this.sendStatus = true
            setTimeout(()=>{
                if(this.counter>0){
                    this.counter--
                    this.onCounter()
                }else{
                    this.counter = 60
                    this.sendStatus = false
                }
            },1000)
        },
        /**
         *  校验方法 目前只校验账户密码是否为空 通过之后调用登录方法
         *  @param account && password
         */
        onRetrieveCheck() {
            if(!validateEmail(this.account)){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.emailTips')
                return
            }
            // if(this.code === ''){
            //     this.accountPrintVisible = true
            //     this.accountPrint = this.$t('login.keyCodeTips')
            //     return
            // }
            // if(!validatePassword(this.newPassword)){
            //     this.accountPrintVisible = true
            //     this.accountPrint = this.$t('login.newPasswordTips')
            //     return
            // }
            this.loadingStatus = true
            this.onRetrieve().then()
        },
        /**
         * 发送验证码
         * @returns {Promise<void>}
         */
        async onSendCode(){
            if(!validateEmail(this.account)){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.emailTips')
                return
            }
            this.onCounter()
            try{
                let params = {
                    email:this.account
                }
                const { code } = await userRetrieveCodeService(params)
                if(code === 0){
                    $event.$emit(EVENT_NAME.LOGIN_POINT_OUT,this.$t('login.sendError'));
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 找回密码方法
         * @returns {Promise<void>}
         */
        async onRetrieve() {
            try {
                let params = {
                    email:this.account,
                    // password:this.newPassword,
                    // code:this.code
                }
                const { code } = await userRetrieveService(params)
                if(code === 1){
                    // this.onSignInEvent()
                    $event.$emit(EVENT_NAME.LOGIN_POINT_OUT,this.$t('login.resetSuccess'));
                }
                if(code === 0){
                    this.accountPrintVisible = true
                    this.accountPrint = this.$t('login.resetFailed')
                }
                if(code === 1002){
                    this.accountPrintVisible = true
                    this.accountPrint = this.$t('login.accountTips')
                }

                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 发送登录、注册组件状态事件
         */
        onSignInEvent() {
            $event.$emit(EVENT_NAME.LOGIN_SWITCH_TIPS);
            $event.$emit(EVENT_NAME.OPEN_LOGON,'SignIn')
        },
    },
    destroyed() {
        window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default RetrievePwd
