<template>
    <div class="layout-information-alerts">
        <!--s: type-->
        <div class="information-type">
            <a href="javascript:" @click="onSelectedType(type.value)" :class="selectedType === type.value?'active':''" :key="key" v-for="(type,key) in subscribeType">{{ type.label }}</a>
        </div>
        <!--e: type-->
        <div class="alerts-list">
            <ul>
                <li :key="key" v-for="(alerts,key) in alertsData.content">
                    <img @click="onQueryDetails(alerts.id)" :src="alerts.images" v-lazy="alerts.images" class="alerts-product-img"/>
                    <div class="alerts-content">
                        <h1 class="title">{{ alerts.brandName }}</h1>
                        <p class="content">{{ alerts.name }}</p>
                        <span class="price">{{ language === 'CN'?onCharacterPrice(alerts.price) : alerts.price }}</span>
                    </div>
                    <a href="javascript:" class="close" @click="onDelGoodsRemindList(alerts.id)"></a>
                </li>
            </ul>
        </div>
        <div class="alerts-page" v-if="alertsData.content && alertsData.content.length!==0">
            <Page :total="alertsData.totalElements" :current="alertsParams.currentPage" :page-size="alertsParams.pageSize"  @on-change="onSearchPage" @on-page-size-change="onSearchPageSize" size="small"></Page>
        </div>
        <div class="alerts-btn" v-if="alertsData.content && alertsData.content.length!==0">
            <span class="tips" v-if="alertsConfirm">{{ $t('userCenter.alertsDeleteTips') }}</span>
            <a href="javascript:" v-if="!alertsConfirm" @click="alertsConfirm = true">{{ $t('userCenter.deleteAll') }}</a>
            <Button shape="circle" :loading="loadingStatus" v-if="alertsConfirm" @click="onDelAllGoodsRemindList">
                <span v-if="!loadingStatus">{{ $t('userCenter.confirm') }}</span>
                <span v-else>{{ $t('userCenter.confirm') }}</span>
            </Button>
            <a href="javascript:" v-if="alertsConfirm" @click="alertsConfirm = false">{{ $t('userCenter.cancel') }}</a>
        </div>
        <div class="alerts-loading" v-if="loadingVisible"></div>
        <!--s: tips-->
        <div class="information-no-data" v-if="alertsData.content && alertsData.content.length === 0">
            <div class="tips-box">
                <span class="tips-image" :style="'background-image:url('+userCenterTips.image+')'"></span>
                <div class="tips-content">
                    <div class="box">
                        <h2 class="tips-title">{{ userCenterTips.title }}</h2>
                        <p class="tips-text">{{ userCenterTips.text }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--e: tips-->
    </div>
</template>
<script>
import Alerts from './alerts'
export default Alerts
</script>
<style lang="less">
@import "alerts";
</style>
