/**
 *  重置密码
 * @type {{}}
 */
import Vue from 'vue'
import { Icon,Button } from 'vant';
Vue.use(Icon,Button);
import { validateEmail, validatePassword } from "@/utils/tools";
import { userResetPassword } from "@/service/user-service";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
const RestPassword = {
    name:'RestPassword',
    computed: {
        ...mapState({
            userAccount: state => state.userCenter.userAccount,  //获取状态管理用户名称
        }),
    },
    data() {
        return {
            loadingStatus:false,
            passwordVisible:false,
            newPasswordVisible:false,
            tipsVisible:false,
            tipsValue:'',
            account:'',
            newPassword:'',
            password:'',
        }
    },
    created() {
        this.account = this.userAccount
    },
    methods: {
        ...mapMutations('userCenter',['SET_USER_ACCOUNT','SET_SELECT_MENU']),
        /**
         * 密码可显示
         */
        onPasswordShow() {
            this.passwordVisible?this.passwordVisible = false:this.passwordVisible = true
        },
        /**
         * 密码可显示
         */
        onNewPasswordShow() {
            this.newPasswordVisible?this.newPasswordVisible = false:this.newPasswordVisible = true
        },
        /**
         *  校验方法 通过之后调用修改邮箱
         *  @param account && password
         */
        onRestAccountCheck(){
            if(!validateEmail(this.account)){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.emailTips')
                return
            }
            if(this.password === ''){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.keyPasswordTips')
                return
            }
            if(!validatePassword(this.newPassword)){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.newPasswordTips')
                return
            }
            if(this.password === this.newPassword){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.resetPasswordTips')
                return
            }
            this.loadingStatus = true
            this.onRestPassword().then()
        },
        /**
         * 修改密码
         * @returns {Promise<void>}
         */
        async onRestPassword(){
            try {
                let params = {
                    oldEmail:this.account,
                    oldPass:this.password,
                    newPass:this.newPassword
                }
                const { code } = await userResetPassword(params)
                if(code === 1){
                    Cookies.remove('token');
                    this.$router.push('/login')
                    this.SET_SELECT_MENU('')
                    this.SET_USER_ACCOUNT('')
                    location.reload()
                }
                if(code === 1001){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.passwordInvalidTips')
                }
                if(code === 1002){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.accountTips')
                }
                if(code === 1003){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.registeredPrompt')
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 发送修改邮箱、密码组件状态事件
         */
        onRestEmailEvent() {
            this.$emit('on-account-subassembly_status')
        }
    },
}
export default RestPassword
