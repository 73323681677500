import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 拉取系统菜单
 * @param params
 * @returns {Promise<*>}
 */
export const getMenuAllService = async () => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/menu/get/all'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 根据父级ID获取子分类列表
 * @param params
 * @returns {Promise<*>}
 */
export const getMenuCategoryService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/category/get/parent?id='+params.categoryId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 根据分类id获取属性列表
 * @param params
 * @returns {Promise<*>}
 */
export const getMenuAttributeService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/category/get/attribute?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 根据分类id获取属性标签
 * @param params
 * @returns {Promise<*>}
 */
export const getMenuListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/category/get/attribute/list?id='+params.id+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询pre数据
 * @param params
 * @returns {Promise<*>}
 */
export const getPreHomeService = async () => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/pre/get/home'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询特惠
 * @param params
 * @returns {Promise<*>}
 */
export const getOffersListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/offers?size='+params.size+'&page='+params.page+'&source='+params.source))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 拉取国家列表
 * @param params
 * @returns {Promise<*>}
 */
export const getCountryListService = async () => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/country/get/letter'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
