<template>
    <div class="layout-preferences">
        <h1 class="layout-preferences-title h5"><a href="javascript:" class="back" @click="onBack"></a>{{$t('userCenter.preferences') }}<a href="javascript:" class="close" @click="onClose"></a></h1>
        <h1 class="preferences-title PC">{{ $t('userCenter.preferences') }}</h1>
        <div class="preferences-box">
            <div class="box" 
            @click="countryVisible ? countryVisible = false: countryVisible = true">
                <div class="box-left">
                    <font class="country">
                    {{ $t('userCenter.country') }}
                    </font>
                    <p class="name">{{ langCode === 'CN'?countrySel.countryCname:countrySel.countryEname }}</p>
                </div>
                <i class="PC" :class="countryVisible ? 'up' : 'down'"></i>
                <i class="h5" :class="countryVisible ? 'up' : 'right'"></i>
            </div>
            <div class="country-list options" v-if="countryVisible">
                <h1 class="layout-preferences-title h5"><a href="javascript:" class="back" @click="countryVisible = false"></a>{{ $t('userCenter.country') }} <span></span> </h1>
                <ul>
                    <li :key="key" v-for="(country,key) in countryList">
                        <h2 class="title">{{ country.letter }}</h2>
                        <div class="content" :key="index" v-for="(item,index) in country.list">
                            <a href="javascript:" @click="onSelectCount(item)">{{ langCode === 'CN'?item.countryCname:item.countryEname }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="preferences-box">
            <div class="box" 
            @click="currencyVisible ? currencyVisible = false : currencyVisible = true">
                <div class="box-left">
                    <font class="country">{{ $t('userCenter.currency') }}</font>
                    <p class="name">{{ currencySel.name }}</p>
                </div>
                <i class="PC" :class="currencyVisible ? 'up' : 'down'"></i>
                <i class="h5" :class="currencyVisible ? 'up' : 'right'"></i>
            </div>
            <div class="options" v-if="currencyVisible">
                <h1 class="layout-preferences-title h5"><a href="javascript:" class="back" @click="currencyVisible = false"></a>{{ $t('userCenter.currency') }} <span></span> </h1>
                <ul>
                    <li :class="currencySel === cur.code?'active':''" :key="key" v-for="(cur,key) in currency" @click="onSelectCurrency(cur)">{{ cur.name }}</li>
                </ul>
            </div>
        </div>
        <div class="preferences-box">
            <div class="box" @click="languageVisible ? languageVisible = false : languageVisible = true">
                <div class="box-left">
                    <font class="country">{{ $t('userCenter.language') }}</font>
                    <p class="name">{{ languageSel.name }}</p>
                </div>
                <i class="PC" :class="languageVisible ? 'up' : 'down'"></i>
                <i class="h5" :class="languageVisible ? 'up' : 'right'"></i>
            </div>
            <div class="options" v-if="languageVisible">
                <h1 class="layout-preferences-title h5"><a href="javascript:" class="back" @click="languageVisible = false"></a>{{ $t('userCenter.language') }} <span></span> </h1>
                <ul>
                    <li :class="languageSel === lang.code?'active':''" :key="key" v-for="(lang,key) in language" @click="onSelectLanguage(lang)">{{ lang.name }}</li>
                </ul>
            </div>
        </div>
        <div class="preferences-sel PC">
            <div class="box">
                <span class="title">{{ $t('userCenter.newsLetter') }}</span>
                <div :key="key" v-for="(subscribe,key) in subscribeType"  @click="onSelectCheck(subscribe.value)" class="d-flex">
                    <i class="label-icon" :class="subscribe.checkBox?'select':'selected'"></i>
                    <span class="label-name" :class="subscribe.checkBox?'label-selected':''">{{ subscribe.label }}</span>
                </div>
                <div @click="onSelectRadio()" class="d-flex">
                    <i class="label-icon" :class="subscribeValue?'radioOn':'radio'"></i>
                    <span class="label-name">{{ $t('userCenter.unsubscribed') }}</span>
                </div>
            </div>
        </div>
        <div class="preferences-sel h5">
            <div class="box">
                <span class="title">{{ $t('userCenter.newsLetter') }}</span>
                <div @click="onSelectRadio()" class="d-flex">
                    <i class="label-icon" :class="subscribeValue?'radioOn':'radio'"></i>
                    <span class="label-name">{{ $t('userCenter.unsubscribed') }}</span>
                </div>
            </div>
            <div>
                <ul>
                    <li :key="key" v-for="(subscribe,key) in subscribeType"  @click="onSelectCheck(subscribe.value)">
                        <i class="label-icon" :class="subscribe.checkBox?'select':'selected'"></i>
                        <span class="label-name" :class="subscribe.checkBox?'label-selected':''">{{ subscribe.label }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="preferences-sel">
            <div class="box">
                <span class="title">{{ $t('userCenter.promotionsNotifications') }}</span>
                <div class="d-flex">
                    <span :key="key" v-for="(radio,key) in radioType"  @click="onRadio(radio.value)" class="d-flex ml-2">
                        <i class="label-icon" :class="radioSelected === radio.value?'radioOn':'radio'"></i>
                        <span class="label-name">{{ radio.label }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="preferences-btn">
            <a href="javascript:">{{ $t('userCenter.cancel') }}</a>
            <Button shape="circle" :loading="loadingStatus" @click="onAddUserPreference">
                <span v-if="!loadingStatus">{{ $t('userCenter.save') }}</span>
                <span v-else>{{ $t('userCenter.save') }}</span>
            </Button>
        </div>
        <div class="preferences-text" v-html="$t('userCenter.preferencesText')"></div>
        <div class="preferences-tips" v-if="tipsVisible">{{ $t('userCenter.saveTips') }}</div>
    </div>
</template>
<script>
import Preferences from './preferences'
export default Preferences
</script>
<style lang="less" scoped>
@import "preferences";
</style>
