const wx = require('weixin-js-sdk');
/**
 * 分享到微博
 * @param title 标题
 * @param pic 图片地址
 */
export const wbAccess = (title,pic,link) => {
   let url = 'https://service.weibo.com/share/share.php?url='+encodeURIComponent(link)+'&title='+title+'&pic='+pic
   window.open(url)
}

export const wxAccess = async (title) => {

   wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
         title: title, // 分享标题
         desc: '', // 分享描述
         link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
         imgUrl: '', // 分享图标
         success: function () {
            // 设置成功
         }
      })
   });

}
