<template>
    <div class="layout-login">
        <div class="layout-login-box">
            <div class="login-info">
                <div class="box">
                    <span class="box-img" :style="'background-image:url('+tipsDataObj.image+')'"></span>
                    <div class="box-text" v-html="tipsDataObj.text"></div>
                </div>
                <a href="javascript:" @click="onClose" class="close"></a>
            </div>
            <div class="layout-account-box">
                <div class="account-box" style="margin-right: 100px">

                    <SignIn v-if="logonName!=='RetrievePwd'"/>
                    <RetrievePwd v-if="logonName !== 'SignIn' && logonName !== 'Register'"/>

                    <h1 class="account-login-with-title">{{ $t('login.with') }}</h1>

                    <div class="account-login-with">
                        <a href="javascript:" class="login-face-book" @click="loginFB">
                        </a>
                        <a href="javascript:" class="login-google"></a>
                        <a href="javascript:" class="login-wx"></a>
                    </div>
                </div>
                <div class="account-box">

                    <Register/>

                    <h1 class="account-login-with-title">{{ $t('login.withRegister') }}</h1>

                    <div class="account-login-with">
                        <a href="javascript:" class="login-face-book" @click="loginFB"></a>
                        <a href="javascript:" class="login-google"></a>
                        <a href="javascript:" class="login-wx"></a>
                    </div>
                </div>
            </div>
            <div class="login-tips" v-show="tipsVisible">{{ tipsValue }}</div>
        </div>
    </div>
</template>
<script>
import Login from './login'
export default Login
</script>
<style lang="less">
@import "login";
</style>
