/**
 *  账号信息
 * @type {{}}
 */
import Vue from 'vue'
import { Icon,Button } from 'vant';
Vue.use(Icon,Button);
import { validateEmail } from "@/utils/tools";
import { mapMutations, mapState } from "vuex";
import { userRestAccountService } from "@/service/user-service";
import Cookies from "js-cookie";
const RestEmail = {
    name:'RestEmail',
    computed: {
        ...mapState({
            userAccount: state => state.userCenter.userAccount,  //获取状态管理用户名称
        }),
    },
    data() {
        return {
            loadingStatus:false,
            passwordVisible:false,
            tipsVisible:false,
            tipsValue:'',
            account:'',
            newAccount:'',
            password:'',
        }
    },
    created() {
        this.account = this.userAccount
    },
    methods: {
        ...mapMutations('userCenter',['SET_USER_ACCOUNT','SET_SELECT_MENU']),
        /**
         * 密码可显示
         */
        onPasswordShow() {
            this.passwordVisible?this.passwordVisible = false:this.passwordVisible = true
        },
        /**
         *  校验方法 通过之后调用修改邮箱
         *  @param account && password
         */
        onRestAccountCheck(){
            if(!validateEmail(this.account)){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.emailTips')
                return
            }
            if(!validateEmail(this.newAccount)){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.newEmailTips')
                return
            }
            if(this.account === this.newAccount){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.resetAccountTips')
                return
            }
            if(this.password === ''){
                this.tipsVisible = true
                this.tipsValue = this.$t('login.keyPasswordTips')
                return
            }
            this.loadingStatus = true
            this.onRestAccount().then()
        },
        /**
         * 修改邮箱
         * @returns {Promise<void>}
         */
        async onRestAccount(){
            try {
                let params = {
                    oldEmail:this.account,
                    newEmail:this.newAccount,
                    oldPass:this.password
                }
                const { code } = await userRestAccountService(params)
                if(code === 1){
                    Cookies.remove('token');
                    this.SET_SELECT_MENU('')
                    this.SET_USER_ACCOUNT('')
                    this.$router.push('/login')
                    location.reload()
                }
                if(code === 1001){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.passwordInvalidTips')
                }
                if(code === 1002){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.accountTips')
                }
                if(code === 1003){
                    this.tipsVisible = true
                    this.tipsValue = this.$t('login.registeredPrompt')
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 发送修改邮箱、密码组件状态事件
         */
        onRestPasswordEvent() {
            this.$emit('on-account-subassembly_status')
        }
    }
}
export default RestEmail
