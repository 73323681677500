import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * post 请求 用户登录换取token
 * @param params
 * @returns {Promise<*>}
 */
export const userSignInService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/auth/login'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 拉取用户信息
 * @param params
 * @returns {Promise<*>}
 */
export const getUserInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/info'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * 重置密码
 * @type {string}
 */
export const userResetPassword = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/updatePass'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 用户注册
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userRegisterService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/createUser'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 退出登录
 * @param params
 * @returns {Promise<*>}
 */
export const userLogoutService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/auth/logout'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改邮箱
 * @param params
 * @returns {Promise<*>}
 */
export const userRestAccountService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/updateEmail'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 发送验证码
 * @param params
 * @returns {Promise<*>}
 */
export const userRetrieveCodeService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/code/email/resetPass?email='+params.email))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 找回密码
 * @param params
 * @returns {Promise<*>}
 */
export const userRetrieveService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/account/sendResetEmail?email='+params.email))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 订阅消息
 * @param params
 * @returns {Promise<*>}
 */
export const userAccountSubscribeService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/subscribe'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 获取用户中心信息
 * @param params
 * @returns {Promise<*>}
 */
export const userCenterInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/account/userCenterInfo'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post facebook登录
 * @param params
 * @returns {Promise<*>}
 */
 export const authByFacebook = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/auth/facebook'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
