/**
 *  意愿清单
 * @type {{}}
 */
import Vue from 'vue'
import { Page } from 'iview';
import { subscribeType, userCenterTips } from "@/service/public/enum";
import { getGoodsCollectionListService,delAllGoodsCollectionService } from "@/service/goods-collection-service";
import VueLazyLoad from "vue-lazyload";
import { mapMutations, mapState } from "vuex";
Vue.component('Page', Page);
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
import { onCharacterPrice } from '@/utils/tools'
const Wishlist = {
    name:'Wishlist',
    computed:{
        ...mapState({
            language: state => state.language.code,  //获取状态语言
        }),
    },
    data() {
        return {
            onCharacterPrice:onCharacterPrice,
            loadingStatus:false,
            loadingVisible:false,
            wishlistConfirm:false, // 删除确认状态
            selectedType:subscribeType[0].value, //选中的分类
            subscribeType:subscribeType, //分类数据
            wishlistData:{}, //数据
            userCenterTips:userCenterTips[2],//提示数据
            wishlistParams:{
                currentPage:1,
                pageSize:10
            },
        }
    },
    created() {
        this.onGoodsCollectionList().then()
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID']),
        /**
         * 切换分类
         * @param value
         */
        onSelectedType(value){
            this.selectedType = value
            this.onGoodsCollectionList().then()
        },
        /**
         * 跳转到详情页
         * @param id
         */
        onQueryDetails(id){
            this.SET_PRODUCT_ID(id)
            this.$router.push('/product/details?id='+id)
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.wishlistParams.currentPage = number
            this.onGoodsCollectionList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.wishlistParams.pageSize = number
            this.onGoodsCollectionList().then()
        },
        /**
         * 查询用户商品收藏列表
         * @returns {Promise<void>}
         */
        async onGoodsCollectionList() {
            try {
                this.wishlistData = {}
                this.loadingVisible = true
                let params = {
                    cid1:this.selectedType,
                    page:this.wishlistParams.currentPage,
                    size:this.wishlistParams.pageSize
                }
                const { code,data } = await getGoodsCollectionListService(params)
                if(code === 1){
                    this.wishlistData = data
                    this.$emit('on-user-center-info')
                }
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
        /**
         * 删除全部商品收藏
         * @returns {Promise<void>}
         */
        async onDelAllGoodsCollectionList() {
            try {
                this.loadingStatus = true
                const { code } = await delAllGoodsCollectionService()
                if(code === 1){
                    this.wishlistConfirm = false
                    this.onGoodsCollectionList().then()
                    this.$emit('on-user-center-info')
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
    },
}
export default Wishlist
