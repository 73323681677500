import Vue from 'vue'
import { Button } from 'iview';
Vue.component('Button', Button);
import $event, {EVENT_NAME} from "@/utils/event-emitter";
import Cookies from 'js-cookie'
import { validateEmail } from '@/utils/tools'
import { userSignInService,getUserInfoService } from "@/service/user-service";
import {mapMutations} from "vuex";
const SignIn = {
    name:'SignIn',
    data() {
        return {
            loadingStatus:false,
            passwordVisible:false,
            accountPrintVisible:false,
            account:'',
            password:'',
            accountPrint:'',
            accountPlaceholder:this.$t('login.account'),
            passwordPlaceholder:this.$t('login.password'),
        }
    },
    mounted() {
        // window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        ...mapMutations('system',['SET_CURRENCY_CODE']),
        ...mapMutations('userCenter',['SET_USER_ACCOUNT']),
        ...mapMutations('language',['SET_CODE']),
        onFocus(name){
            if(name === 'account'){
                this.accountPlaceholder = ''
            }
            if(name === 'password'){
                this.passwordPlaceholder = ''
            }
        },
        onBlur(name){
            if(name === 'account'){
                this.accountPlaceholder = this.$t('login.account')
            }
            if(name === 'password'){
                this.passwordPlaceholder = this.$t('login.password')
            }
        },
        /**
         * 密码可显示
         */
        onPasswordShow() {
            this.passwordVisible?this.passwordVisible = false:this.passwordVisible = true
        },
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onSignInCheck()
            }
        },
        /**
         *  校验方法 目前只校验账户密码是否为空 通过之后调用登录方法
         *  @param account && password
         */
        onSignInCheck() {
            if(!validateEmail(this.account)){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.emailTips')
                return
            }
            if(this.password === ''){
                this.accountPrintVisible = true
                this.accountPrint = this.$t('login.keyPasswordTips')
                return
            }
            this.loadingStatus = true
            this.onSignIn().then()
        },

        /**
         * 登录方法
         * @returns {Promise<void>}
         */
        async onSignIn() {
            try {
                let params = {
                    username:this.account,
                    password:this.password
                }
                const { code,token } = await userSignInService(params)
                if(token){
                    Cookies.set('token',token,{ expires: 1 })
                    this.onUserInfo().then()
                }
                if(code === 1001){
                    this.accountPrintVisible = true
                    this.accountPrint = this.$t('login.passwordInvalidTips')
                }
                if(code === 1002){
                    this.accountPrintVisible = true
                    this.accountPrint = this.$t('login.accountTips')
                }
                this.loadingStatus = false

            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 获取用户信息
         * @returns {Promise<void>}
         */
        async onUserInfo(){
            try {
                const { user } = await getUserInfoService({})
                this.SET_USER_ACCOUNT(user.email)
                this.loadingStatus = false
                $event.$emit(EVENT_NAME.LOGIN_TOKEN);
                $event.$emit(EVENT_NAME.OPEN_LOGON_CLOSE);
                this.SET_CURRENCY_CODE(user.currency)
                $event.$emit(EVENT_NAME.UPDATE_CURRENCY)
                this.SET_CODE(user.language)
                localStorage.setItem('language',user.language)
                location.reload()

            }catch (error){
                console.log(error)
                this.loadingStatus = false
            }
        },
        /**
         * 发送忘记密码组件状态事件
         */
        onForgotPassword() {
            $event.$emit(EVENT_NAME.LOGIN_SWITCH_TIPS);
            $event.$emit(EVENT_NAME.OPEN_LOGON,'RetrievePwd')
        }
    },
    destroyed() {
        // window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default SignIn
