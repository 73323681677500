<template>
  <header class="header-body">
    <!--s: pc -->
    <div class="header-content" v-if="!isPhone()">
      <!--s: Header-->
      <div class="header-box">
        <div class="header-frame">
          <div class="left-side">
            <div class="frame-li country">
              <CustomSelect
                v-model="countryVisible"
                show="mouseover"
                :title="selectCountry.countryCode"
              >
                <div class="header-country">
                  <ul @scroll="carouselScroll">
                    <li :key="key" v-for="(count, key) in displayCountry">
                      <h2 class="title">{{ count.letter }}</h2>
                      <div
                        class="content"
                        :key="index"
                        v-for="(item, index) in count.list"
                      >
                        <a href="javascript:" @click="onCountry(item)">{{
                          langCode === "CN"
                            ? item.countryCname
                            : item.countryEname
                        }}</a>
                      </div>
                    </li>
                  </ul>
                  <div class="slider-page-n">
                    <span class="slider" :style="'top:' + slider + '%'"></span>
                  </div>
                </div>
              </CustomSelect>
            </div>
            <div class="frame-li">
              <CustomSelect
                v-model="currencyVisible"
                show="mouseover"
                :title="selectCurrency.name"
              >
                <div class="header-currency">
                  <ul>
                    <li :key="key" v-for="(currency, key) in currency">
                      <a href="javascript:" @click="onCurrency(currency)">{{
                        currency.name
                      }}</a>
                    </li>
                  </ul>
                </div>
              </CustomSelect>
            </div>
            <div class="frame-li">
              <CustomSelect
                v-model="languageVisible"
                show="mouseover"
                :title="selectLanguage.name"
              >
                <div class="header-currency">
                  <ul>
                    <li :key="key" v-for="(lang, key) in language">
                      <a
                        href="javascript:"
                        @click="onLanguage(lang)"
                        :style="
                          lang.code === 'CN'
                            ? ' font-family: FZLT-Regular;'
                            : 'font-family: Montserrat-Regular'
                        "
                        >{{ lang.name }}</a
                      >
                    </li>
                  </ul>
                </div>
              </CustomSelect>
            </div>
          </div>
          <div class="right-side">
            <div class="frame-li frame-right">
              <CustomSelect
                v-if="token"
                v-model="alertVisible"
                show="mouseover"
                position="frame-right"
                placement="right"
                :icon="!alertNew || alertNew.length === 0 ? 'icon' : 'icon-new'"
                :title="
                  alertNew && alertNew.length !== 0 ? alertNew.length : ''
                "
              >
                <div class="header-alert">
                  <div class="alert-title">
                    <span
                      class="name"
                      v-if="alertNew && alertNew.length !== 0"
                      v-html="
                        $t('header.newAlert', { number: alertNew.length })
                      "
                    ></span>
                    <div
                      class="title-box"
                      v-if="alertNew && alertNew.length !== 0"
                    >
                      <a
                        href="javascript:"
                        class="title-link"
                        @click="onAlert"
                        >{{ $t("header.alertViewAll") }}</a
                      ><i class="icon"></i>
                    </div>
                  </div>
                  <div
                    class="alert-list"
                    v-if="alertNew && alertNew.length !== 0"
                  >
                    <ul>
                      <li
                        :key="key"
                        v-for="(alerts, key) in newAlertsData.content"
                      >
                        <img
                          @click="onQueryDetails(alerts.id)"
                          :src="alerts.images"
                          class="alert-image"
                        />
                        <div class="alert-text">
                          <div class="box">
                            <h1 class="title">{{ alerts.brandName }}</h1>
                            <span class="price">{{
                              langCode === "CN"
                                ? onCharacterPrice(alerts.price)
                                : alerts.price
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="alert-tips"
                    v-if="!alertNew || alertNew.length === 0"
                  >
                    <span class="tips-image"></span>
                    <h2 class="title">{{ $t("header.tipsTitle") }}</h2>
                    <p class="text">{{ $t("header.tipsText") }}</p>
                  </div>
                </div>
              </CustomSelect>
            </div>
            <div class="frame-li" style="margin-left: 15px">
              <a
                v-if="!token"
                href="javascript:"
                @click="onLinks(linksName.LINKS_LOGIN)"
                >{{ $t("header.login") }}</a
              >
              <CustomSelect
                v-if="token"
                v-model="userCenterVisible"
                placement="right"
                :title="$t('header.account')"
              >
                <div class="header-currency">
                  <ul>
                    <li :key="key" v-for="(menu, key) in userMenu">
                      <a href="javascript:" @click="onLinks(menu.label)">{{
                        menu.name
                      }}</a>
                    </li>
                  </ul>
                </div>
              </CustomSelect>
            </div>
            <div class="frame-li">
              <a href="javascript:" class="history" @click="onHistory">{{
                $t("header.history")
              }}</a>
            </div>
            <div class="frame-li">
              <a href="javascript:" class="history" @click="onMarketFast">{{
                $t("header.search")
              }}</a>
            </div>
          </div>
        </div>
        <div
          class="header-logo"
          @click="onLinks(linksName.LINKS_HOME_PAGE)"
        ></div>
      </div>
      <!--e: Header-->

      <!--s: Classify-->
      <div class="header-classify" v-if="$route.path !== '/pre'">
        <div class="classify-box">
          <ul>
            <li
              class="classify-level-li"
              :key="classify.id"
              v-for="classify in menuObj"
            >
              <a
                href="javascript:"
                class="classify-level-title"
                :style="onTypeStyle(classify.id)"
                :class="selectType === classify.id ? 'active' : ''"
                @mouseover="onMouseoverType(classify)"
                @mouseout="onMouseoutType"
                @click="onSelectType(classify.id)"
                >{{ classify.name }}</a
              >
              <div
                class="menu-drop-down"
                v-show="menuVisible"
                @mouseover="onClassifyThree(classify)"
                @mouseout="onMouseoutType"
              >
                <ul class="menu-offers" v-if="classify.id === 5">
                  <li :key="key" v-for="(offers, key) in classify.offers">
                    <img
                      :src="offers.logoImage"
                      v-lazy="offers.logoImage"
                      class="offers-image"
                    />
                    <div class="offers-exclusive">
                      <span class="name" v-if="offers.exclusive !== 0">{{
                        $t("offers.exclusive")
                      }}</span>
                      <span
                        class="number"
                        :style="offers.exclusive === 0 ? 'width:100%' : ''"
                        >{{ offers.saleContent }}</span
                      >
                    </div>
                    <div class="content">
                      <div class="box" v-html="offers.saleText"></div>
                    </div>
                    <div class="code">
                      {{ $t("offers.code") }}: <b>{{ offers.code }}</b>
                    </div>
                    <div class="time">
                      {{ $t("offers.expire") }}: {{ offers.endTime }}
                    </div>
                    <div class="show">
                      <a
                        href="javascript:"
                        @click="offers.visible = !offers.visible"
                        >{{
                          offers.visible
                            ? $t("offers.hide")
                            : $t("offers.share")
                        }}
                        <i
                          class="ivu-icon"
                          :class="
                            offers.visible
                              ? 'ivu-icon-ios-arrow-up'
                              : 'ivu-icon-ios-arrow-down'
                          "
                        ></i>
                      </a>
                    </div>
                    <div class="offers-btn" v-if="!offers.visible">
                      <a
                        href="javascript:"
                        class="btn"
                        @click="onOffersLink(offers)"
                        v-html="$t('offers.shopNow')"
                      ></a>
                    </div>
                    <div class="offers-share" v-if="offers.visible">
                      <div class="box">
                        <a href="javascript:" class="face-book"></a>
                        <a href="javascript:" class="pinterest"></a>
                        <a href="javascript:" class="twitter"></a>
                        <a
                          href="javascript:"
                          @click="onShare('wb', offers)"
                          class="sina"
                        ></a>
                      </div>
                      <div class="box">
                        <a href="javascript:" class="wx"></a>
                        <a href="javascript:" class="email"></a>
                        <a href="javascript:" class="icon-code"></a>
                        <a href="javascript:" class="link"></a>
                      </div>
                    </div>
                  </li>
                  <li class="more-box">
                    <div class="box" @click="onLinksOffers">
                      <span class="box-n more">{{ classify.ending }}</span>
                      <p class="box-c" v-html="classify.endingText"></p>
                    </div>
                  </li>
                </ul>
                <ul v-if="classify.id !== 5">
                  <li
                    class="classify-second-li"
                    :key="secondLevel.id"
                    v-for="(secondLevel, index) in classify.menu1"
                  >
                    <a
                      href="javascript:"
                      class="classify-second-title"
                      :style="onStyle(secondLevel)"
                      @mouseover="onClassifySecondLevel(secondLevel, classify)"
                      @mouseout="onMouseoutType"
                      ><font>{{ secondLevel.name }}</font></a
                    ><!--s:  @click="onSecondList(secondLevel,classify.id)"-->
                    <div
                      class="three-level-menu"
                      :style="
                        threeVisible || index === 0 ? 'display:block' : ''
                      "
                      @mouseover="onClassifyThree(classify)"
                      @mouseout="onMouseoutType"
                    >
                      <div
                        class="three-box"
                        :style="
                          threeVisible ? 'padding-top:0;border-top:none' : ''
                        "
                      >
                        <div
                          class="level-screen"
                          v-if="secondLevel.isBrand === 1"
                        >
                          <a
                            href="javascript:"
                            :class="
                              brandLetterSelectLetter === brandLetter.letter
                                ? 'active'
                                : ''
                            "
                            @click="
                              onBrandList(
                                classify,
                                secondLevel,
                                'menu2',
                                brandLetter
                              )
                            "
                            @mouseover="onBrandLetterSelect(brandLetter)"
                            :key="key"
                            v-for="(brandLetter, key) in brandLetterObj"
                            >{{ brandLetter.letter }}</a
                          >
                          <a
                            href="javascript:"
                            @click="onBrandList(classify, secondLevel, 'menu2')"
                            >{{ $t("menu.viewAll") }}</a
                          >
                        </div>
                        <div
                          class="screen-content"
                          v-if="secondLevel.isBrand === 1"
                        >
                          <h1 class="screen-title">
                            {{ $t("menu.featuredBrands") }}
                          </h1>
                          <div class="screen-box">
                            <div
                              class="box"
                              :key="key"
                              v-for="(
                                brandLetterSelect, key
                              ) in brandLetterSelectList"
                            >
                              <a
                                href="javascript:"
                                @click="
                                  onTertiaryList(
                                    brandLetterSelect,
                                    secondLevel,
                                    classify.id,
                                    1
                                  )
                                "
                                >{{ brandLetterSelect.name }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="level-menu-content"
                          v-if="classify.id !== 3"
                        >
                          <div class="level-class-content">
                            <div
                              class="content"
                              v-if="
                                secondLevel.category1 && secondLevel.id === 8
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(0, 10)"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  v-if="index !== 0"
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                secondLevel.category1 &&
                                secondLevel.id === 8 &&
                                secondLevel.category1.length >= 10
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(
                                  9,
                                  secondLevel.category1.length
                                )"
                              >
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 10 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                (secondLevel.category1 &&
                                  secondLevel.id === 9) ||
                                secondLevel.id === 17
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(0, 9)"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  v-if="index !== 0"
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                (secondLevel.category1 &&
                                  secondLevel.id === 9) ||
                                (secondLevel.id === 17 &&
                                  secondLevel.category1.length >= 9)
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(
                                  8,
                                  secondLevel.category1.length
                                )"
                              >
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 9 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                secondLevel.category1 && secondLevel.id === 10
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(0, 8)"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  v-if="index !== 0"
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                secondLevel.category1 &&
                                secondLevel.id === 10 &&
                                secondLevel.category1.length >= 8
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(
                                  level, index
                                ) in secondLevel.category1.slice(
                                  7,
                                  secondLevel.category1.length
                                )"
                              >
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 8 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="
                                secondLevel.category1 &&
                                secondLevel.id !== 8 &&
                                secondLevel.id !== 9 &&
                                secondLevel.id !== 10 &&
                                secondLevel.id !== 17
                              "
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category1"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category1.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category1[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div class="content" v-if="secondLevel.category2">
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category2"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category2.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category2[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="level-menu-content level-kids"
                          v-if="classify.id === 3"
                          :class="onKidsStyle(secondLevel)"
                        >
                          <div class="content" v-if="secondLevel.category1">
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category1"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category1.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category1[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="content"
                            v-if="
                              (secondLevel.category2 &&
                                secondLevel.isSale === 1) ||
                              secondLevel.isNew === 1
                            "
                          >
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category2"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category2.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category2[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="content"
                            v-if="
                              (secondLevel.category3 &&
                                secondLevel.isSale === 1) ||
                              secondLevel.isNew === 1
                            "
                          >
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category3"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category3.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category3[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>

                          <div
                            class="kids-3"
                            v-if="
                              secondLevel.isSale !== 1 &&
                              secondLevel.isNew !== 1 &&
                              (secondLevel.category2 || secondLevel.category3)
                            "
                          >
                            <div class="content" v-if="secondLevel.category2">
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category2"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category2.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category2[0].name,
                                      1
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div class="content" v-if="secondLevel.category3">
                              <div
                                class="box"
                                :key="level.id"
                                v-for="level in secondLevel.category3.slice(
                                  1,
                                  secondLevel.category3.length
                                )"
                              >
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category3[0].name,
                                      2
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                          </div>

                          <div class="content" v-if="secondLevel.category4">
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category4"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category4.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category4[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="content"
                            v-if="
                              (secondLevel.category5 &&
                                secondLevel.isSale === 1) ||
                              secondLevel.isNew === 1
                            "
                          >
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category5"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category5.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category5[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="content"
                            v-if="
                              (secondLevel.category6 &&
                                secondLevel.isSale === 1) ||
                              secondLevel.isNew === 1
                            "
                          >
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category6"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category6.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category6[0].name
                                  )
                                "
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="kids-3"
                            v-if="
                              secondLevel.isSale !== 1 &&
                              secondLevel.isNew !== 1 &&
                              (secondLevel.category5 || secondLevel.category6)
                            "
                            style="margin-left: 45px"
                          >
                            <div class="content" v-if="secondLevel.category5">
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category5"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category5.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category5[0].name,
                                      3
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div class="content" v-if="secondLevel.category6">
                              <div
                                class="box"
                                :key="level.id"
                                v-for="level in secondLevel.category6.slice(
                                  1,
                                  secondLevel.category6.length
                                )"
                              >
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category6[0].name,
                                      4
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="content"
                            v-if="
                              (secondLevel.category7 &&
                                secondLevel.isSale === 1) ||
                              secondLevel.isNew === 1
                            "
                            style="margin-right: 0px"
                          >
                            <div
                              class="box"
                              :key="level.id"
                              v-for="(level, index) in secondLevel.category7"
                              v-show="index !== 0"
                            >
                              <span class="title" v-if="index === 0">{{
                                level.name
                              }}</span>
                              <a
                                href="javascript:"
                                :style="onIsSale(level, secondLevel)"
                                :class="
                                  index === secondLevel.category7.length - 1 &&
                                  level.name == $t('header.viewAll')
                                    ? 'title'
                                    : ''
                                "
                                v-if="index !== 0"
                                @click="
                                  onTertiaryList(
                                    level,
                                    secondLevel,
                                    classify.id,
                                    0,
                                    secondLevel.category7[0].name
                                  )
                                "
                                style="font-weight: bold"
                                >{{ level.name }}</a
                              >
                            </div>
                          </div>
                          <div
                            class="kids-3"
                            v-if="
                              secondLevel.isSale !== 1 &&
                              secondLevel.isNew !== 1 &&
                              secondLevel.id === 26 &&
                              (secondLevel.category7 || secondLevel.category8)
                            "
                            style="margin-right: 0px"
                          >
                            <div
                              class="content"
                              v-if="secondLevel.category7"
                              style="width: 155px"
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category7"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category7.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category7[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                            <div
                              class="content"
                              v-if="secondLevel.category8"
                              style="width: 155px"
                            >
                              <div
                                class="box"
                                :key="level.id"
                                v-for="(level, index) in secondLevel.category8"
                              >
                                <span class="title" v-if="index === 0">{{
                                  level.name
                                }}</span>
                                <a
                                  href="javascript:"
                                  :style="onIsSale(level, secondLevel)"
                                  :class="
                                    index ===
                                      secondLevel.category8.length - 1 &&
                                    level.name == $t('header.viewAll')
                                      ? 'title'
                                      : ''
                                  "
                                  v-if="index !== 0"
                                  @click="
                                    onTertiaryList(
                                      level,
                                      secondLevel,
                                      classify.id,
                                      0,
                                      secondLevel.category8[0].name
                                    )
                                  "
                                  >{{ level.name }}</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="level-menu-brand"
                          v-if="
                            secondLevel.brands &&
                            secondLevel.brands.length !== 0
                          "
                          :style="
                            secondLevel.id === 27 || secondLevel.id === 28
                              ? 'margin-left:0'
                              : ''
                          "
                        >
                          <div class="content">
                            <div class="box">
                              <span class="title">{{ $t("menu.brands") }}</span>
                            </div>
                            <div
                              class="box"
                              :key="levelBrands.id"
                              v-for="(levelBrands, index) in secondLevel.brands"
                            >
                              <a
                                href="javascript:"
                                :class="
                                  index === levelBrands.length - 1
                                    ? 'title'
                                    : ''
                                "
                                @click="
                                  onTertiaryList(
                                    levelBrands,
                                    secondLevel,
                                    classify.id,
                                    1
                                  )
                                "
                                >{{ levelBrands.name }}</a
                              >
                            </div>
                            <div class="box">
                              <a
                                href="javascript:"
                                class="title"
                                @click="
                                  onBrandList(classify, secondLevel, 'menu3')
                                "
                                >{{ $t("header.viewAll") }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="menu-advert">
                          <span
                            class="advert-img"
                            @click="onMarketLink(secondLevel, classify.id)"
                            :class="secondLevel.isBrand === 1 ? 'advert-h' : ''"
                            :style="
                              'background-image:url(' +
                              secondLevel.coverImage +
                              ')'
                            "
                          ></span>
                          <div class="advert-b">
                            <span class="advert-name">{{
                              secondLevel.coverTitle
                            }}</span>
                            <a href="javascript:" class="shop-now">
                              {{ $t("menu.shopNow") }}
                              <i class="icon"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--e: Classify-->
    </div>
    <!--e: pc -->

    <!--s: h5 -->
    <div class="header-info" v-if="isPhone()">
      <!--s: Header info -->
      <div class="box-left">
        <div class="header-box" @click="onMenuShow">
          <span class="icon icon-more"></span>
        </div>
        <div class="header-box" @click="onMarketFast">
          <span class="icon icon-search"></span>
        </div>
      </div>
      <div class="box-right">
        <div class="header-box">
          <span class="icon icon-notice"></span>
        </div>
        <div class="header-box" @click="onLogin">
          <span
            class="icon"
            :class="token ? 'icon-user-black' : 'icon-user'"
          ></span>
        </div>
      </div>
      <!--e: Header info -->
      <div
        class="header-logo"
        @click="onLinks(linksName.LINKS_HOME_PAGE)"
      ></div>
    </div>

    <!--s: Search -->
    <div class="frame-search" id="frameSearchId" v-if="searchVisible">
      <div class="search-input-box">
        <div class="search-input">
          <input
            v-model="searchInput"
            type="text"
            class="input"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onMarketSearchList"
            @keyup="onMarketSearchClick"
            :placeholder="searchInputPlaceholder"
          />
          <a
            href="javascript:"
            class="close"
            v-if="searchInput !== ''"
            @click="
              searchInput = '';
              searchListInput = [];
            "
          ></a>
        </div>
        <a href="javascript:" class="icon" @click="onSearchInput"></a>
      </div>
      <div class="search-label">
        <div class="box">
          <ul>
            <li
              :key="key"
              v-for="(subscribe, key) in subscribeType"
              @click="onSearchLabelCheck(subscribe.value)"
            >
              <i
                class="label-icon"
                :class="searchType === subscribe.value ? 'select' : 'selected'"
              ></i>
              <!-- <span
                class="label-name"
                :style="onSubStyle(subscribe.value)"
                :class="searchType === subscribe.value ? 'title' : ''"
                >{{ subscribe.label }}</span
              > -->
              <span
                class="label-name"
                :class="searchType === subscribe.value ? 'title' : ''"
                >{{ subscribe.label }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="search-list-input" v-if="searchListInput.length !== 0">
        <ul>
          <li :key="key" v-for="(list, key) in searchListInput">
            <a href="javascript:" @click="onSearchLink(list)"
              >{{ list.name }}
              <font>{{
                list.type === 1 ? $t("menu.brands") : $t("menu.category")
              }}</font></a
            >
          </li>
        </ul>
      </div>
      <div
        class="search-list-li"
        v-if="
          (searchListVisible && !isPhone()) ||
          (searchListVisible && isPhone() && searchListInput.length === 0)
        "
      >
        <h1 class="title">{{ $t("headline.popularKeywords") }}</h1>
        <ul>
          <li
            :key="key"
            v-for="(keywordList, key) in searchObj.keywordList"
            @click="onProductList(keywordList)"
          >
            <a href="javascript:">{{ keywordList.coverTitle }}</a>
          </li>
        </ul>
      </div>
      <div
        class="search-list-li"
        v-if="
          (searchListVisible && !isPhone()) ||
          (searchListVisible && isPhone() && searchListInput.length === 0)
        "
      >
        <h1 class="title">{{ $t("headline.popularBrands") }}</h1>
        <ul>
          <li
            :key="key"
            v-for="(brandList, key) in searchObj.brandList"
            @click="onProductList(brandList, 'brand')"
          >
            <a href="javascript:">{{ brandList.coverTitle }}</a>
          </li>
        </ul>
      </div>
      <div
        class="search-list-li"
        v-if="
          (searchListVisible && !isPhone()) ||
          (searchListVisible && isPhone() && searchListInput.length === 0)
        "
      >
        <h1 class="title">{{ $t("headline.featuredProducts") }}</h1>
        <ul>
          <li
            :key="key"
            v-for="(featuredList, key) in searchObj.featuredList"
            @click="onProductList(featuredList)"
          >
            <a href="javascript:">{{ featuredList.coverTitle }}</a>
          </li>
        </ul>
      </div>
      <a
        href="javascript:"
        class="custom-up"
        @click="
          searchVisible = false;
          searchListInput = [];
          searchListVisible = true;
        "
      >
        <i class="ivu-icon ivu-icon-ios-up"></i>
      </a>
    </div>
    <!--e: Search -->

    <!--s: Menu-->
    <template v-if="isPhone()">
      <div
        class="menu-box"
        v-if="
          $route.path !== '/pre' &&
          $route.path !== '/product/list' &&
          $route.path !== '/product/details' &&
          $route.path !== '/brands' &&
          $route.path !== '/help-center' &&
          $route.path !== '/contact-us' &&
          $route.path !== '/about-us' &&
          $route.path !== '/accessibility' &&
          $route.path !== '/terms-conditions' &&
          $route.path !== '/privacy-policy' &&
          $route.path !== '/discover/list' &&
          $route.path !== '/featured/list' &&
          $route.path !== '/image/text'
        "
      >
        <div class="menu-classify-level">
          <a
            href="javascript:"
            :class="menuType === classify.id || menuType === classify.id.toString() ? 'active' : ''"
            :key="key"
            v-for="(classify, key) in menuObj"
            @click="onSelectType(classify.id)"
            >{{ classify.name }}</a
          >
        </div>
      </div>
      <div class="menu-mask" v-if="menuVisible">
        <div class="mask-top">
          <a href="javascript:" class="close" @click="onClose()"></a>
          <a href="javascript:" class="logo"></a>
        </div>
        <div class="menu-classify-level">
          <a
            href="javascript:"
            :class="menuType === classify.id ? 'active' : ''"
            :key="key1"
            v-for="(classify, key1) in menuObj"
            @click="onSelectType(classify.id)"
            >{{ classify.name }}</a
          >
        </div>

        <!--s: Content-->
        <div class="menu-content">
          <div class="menu-list">
            <ul v-if="secondObj && secondObj.length">
              <li
                :style="
                  second.name === 'Brands' ||
                  second.name === '品牌' ||
                  ((second.name === 'Pre-Owned' ||
                    second.name === '臻品家居') &&
                    menuType === 4)
                    ? 'margin-bottom:.35rem'
                    : ''
                "
                :key="key"
                v-for="(second, key) in secondObj"
                @click="
                  second.isBrand
                    ? onBrandList(selectMenu, second, 'menu2')
                    : menuType === 4
                    ? onTertiaryList(second, selectSecondList, 4, 0)
                    : onSecondList(second)
                "
              >
                {{ second.name }}
              </li>
            </ul>
          </div>
          <div class="menu-user">
            <ul>
              <li @click="onCountryShow">
                <span class="name">{{ $t("userCenter.country") }}</span>
                <span class="content">{{
                  langCode === "CN"
                    ? selectCountry.countryCname
                    : selectCountry.countryEname
                }}</span>
              </li>
              <li @click="onCurrencyShow">
                <span class="name">{{ $t("userCenter.currency") }}</span>
                <span class="content">{{ selectCurrency.name }}</span>
              </li>
              <li @click="onLanguageShow">
                <span class="name">{{ $t("userCenter.language") }}</span>
                <span class="content">{{ selectLanguage.name }}</span>
              </li>
              <li class="history">
                <span class="name">{{ $t("header.history") }}</span>
              </li>
            </ul>
            <div class="user-btn" v-if="userAccount === ''">
              <a
                href="javascript:"
                class="login"
                @click="onLinkLogin('login')"
                >{{ $t("header.login") }}</a
              >
              <a
                href="javascript:"
                class="create"
                @click="onLinkLogin('create')"
                >{{ $t("login.registerBtn") }}</a
              >
            </div>
          </div>
        </div>
        <!--e: Content-->
      </div>
      <div class="menu-language" v-if="languageVisible">
        <div class="language-top">
          <a href="javascript:" class="back" @click="onLanguageClose"></a>
          <a href="javascript:" class="logo"></a>
          <a href="javascript:" class="close" @click="onClose"></a>
        </div>
        <h1 class="language-title">{{ $t("userCenter.language") }}</h1>
        <div class="language-list">
          <ul>
            <li
              :key="key"
              v-for="(lang, key) in language"
              @click="onLanguage(lang)"
            >
              <span class="name">{{ lang.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="menu-language" v-if="countryVisible">
        <div class="language-top">
          <a href="javascript:" class="back" @click="onCountryClose"></a>
          <a href="javascript:" class="logo"></a>
          <a href="javascript:" class="close" @click="onClose"></a>
        </div>
        <h1 class="language-title">{{ $t("userCenter.country") }}</h1>
        <div class="language-list country-list header-country">
          <!-- s: country search -->
          <div class="search-wrapper" ref="searchWrapper">
            <p class="search-text" v-if="!isSearch" @click="showSearch(true)">
              {{ $t("header.search") }}
            </p>
            <input
              class="search-input"
              v-else
              type="text"
              :placeholder="$t('header.search')"
              @input="changeSearch"
              ref="searchInput"
            />
          </div>
          <!-- e: country search -->
          <ul @scroll="carouselScroll">
            <li :key="key" v-for="(count, key) in displayCountry">
              <h2 class="title">{{ count.letter }}</h2>
              <div
                class="content"
                :key="index"
                v-for="(item, index) in count.list"
              >
                <a href="javascript:" @click="onCountry(item)">{{
                  langCode === "CN" ? item.countryCname : item.countryEname
                }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="menu-language" v-if="currencyVisible">
        <div class="language-top">
          <a href="javascript:" class="back" @click="onCurrencyClose"></a>
          <a href="javascript:" class="logo"></a>
          <a href="javascript:" class="close" @click="onClose"></a>
        </div>
        <h1 class="language-title">{{ $t("userCenter.currency") }}</h1>
        <div class="language-list">
          <ul>
            <li
              :key="key"
              v-for="(currency, key) in currency"
              @click="onCurrency(currency)"
            >
              <span class="name">{{ currency.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="menu-mask" v-if="tertiaryVisible">
        <div class="mask-top">
          <a href="javascript:" class="back" @click="onTertiaryClose"></a>
          <a href="javascript:" class="close" @click="onClose"></a>
          <a href="javascript:" class="logo"></a>
        </div>
        <div class="menu-classify-level">
          <a
            href="javascript:"
            :class="menuType === classify.id ? 'active' : ''"
            :key="key"
            v-for="(classify, key) in menuObj"
            @click="onTertiarySelectType(classify.id)"
            >{{ classify.name }}</a
          >
        </div>
        <div class="menu-content">
          <div
            class="menu-li"
            :key="key"
            v-for="(tertiary, key) in tertiaryObj"
          >
            <h1 class="title">{{ tertiary.name }}</h1>
            <ul>
              <li
                :key="list.id"
                v-for="(list, index) in tertiary.list"
                @click="
                  tertiary.isBrand
                    ? onBrandList(classify, tertiary, 'menu2')
                    : onTertiaryList(
                        list,
                        selectSecondList,
                        menuType,
                        tertiary.name === 'Brand' || tertiary.name === '品牌'
                          ? 1
                          : 0
                      )
                "
              >
                <span
                  class="name"
                  :class="
                    index === tertiary.list.length - 1 &&
                    list.name == $t('header.viewAll')
                      ? 'bold'
                      : ''
                  "
                  >{{ list.name }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <!--e: Menu-->

    <!--e: h5 -->

    <!--s: Like tips-->
    <div class="layout-like-tips" v-if="likeTipsVisible">
      <div class="like-tips-box">
        <span
          class="tips-image"
          :style="'background-image: url(' + likeTipsObj.image + ')'"
        ></span>
        <div class="tips-content">
          <div class="box">
            <h2 class="tips-title">{{ $t("carousel.tipsTitle") }}</h2>
            <p class="tips-text">{{ likeTipsObj.text }}</p>
          </div>
          <div class="tips-btn">
            <a href="javascript:" @click="onRegister">{{
              $t("carousel.signUp")
            }}</a>
            /
            <a href="javascript:" @click="onLogin" class="arrow">{{
              $t("carousel.login")
            }}</a>
          </div>
        </div>
      </div>
      <a href="javascript:" class="like-clear" @click="onClear"></a>
    </div>
    <!--e: Like tips-->

    <!--s: History-->
    <div
      class="layout-history"
      :style="$route.path === '/pre' ? 'top:65px' : ''"
      v-if="historyVisible"
    >
      <div class="low-lining" @click="onClose"></div>
      <div class="history-body">
        <div class="history-box">
          <a href="javascript:" @click="onClose" class="close"></a>
          <History :data="historyList" @on-close="onClose" />
        </div>
      </div>
    </div>
    <!--e: History-->

    <!--s: h5 login-->
    <div class="layout-login-box" v-if="loginVisible">
      <div class="login-tab">
        <a
          href="javascript:"
          :class="logonName == 'login' ? 'active' : ''"
          @click="onSwitchLogon('login')"
          >{{ $t("login.loginBtn") }}</a
        >
        <a
          href="javascript:"
          :class="logonName == 'create' ? 'active' : ''"
          @click="onSwitchLogon('create')"
          >{{ $t("login.registerBtn") }}</a
        >
      </div>
      <div class="login-box" v-if="logonName === 'login'">
        <div class="box-input">
          <input
            v-model="account"
            type="text"
            :placeholder="$t('login.account')"
            class="input"
          />
        </div>
        <div class="box-input border-bottom">
          <input
            v-model="password"
            :type="passwordVisible ? 'text' : 'password'"
            :placeholder="$t('login.password')"
            class="input"
          />
          <a href="javascript:" @click="onPasswordShow">{{
            passwordVisible ? $t("login.hide") : $t("login.show")
          }}</a>
        </div>
        <div class="box-print" v-show="accountPrintVisible">
          {{ accountPrint }}
        </div>
        <div class="box-btn">
          <van-button
            :loading="loadingStatus"
            type="info"
            @click="onSignInCheck"
          >
            <span v-if="!loadingStatus">{{ $t("login.loginBtn") }}</span>
            <span v-else>{{ $t("login.loginBtn") }}</span>
          </van-button>
        </div>
      </div>
      <div class="login-box" v-if="logonName === 'create'">
        <div class="box-input">
          <input
            v-model="account"
            type="text"
            :placeholder="$t('login.account')"
            class="input"
          />
        </div>
        <div class="box-input border-bottom">
          <input
            v-model="password"
            :type="passwordVisible ? 'text' : 'password'"
            :placeholder="$t('login.password')"
            class="input"
          />
          <a href="javascript:" @click="onPasswordShow">{{
            passwordVisible ? $t("login.hide") : $t("login.show")
          }}</a>
        </div>
        <div class="box-print" v-show="accountPrintVisible">
          {{ accountPrint }}
        </div>
        <div class="box-btn">
          <van-button
            :loading="loadingStatus"
            type="info"
            @click="onRegisterInCheck"
          >
            <span v-if="!loadingStatus">{{ $t("login.registerBtn") }}</span>
            <span v-else>{{ $t("login.registerBtn") }}</span>
          </van-button>
        </div>
      </div>
      <div class="box-forget" v-if="logonName === 'login'">
        <a href="javascript:">{{ $t("login.forgetPassword") }}</a>
      </div>
      <h1 class="account-login-with-title">{{ $t("login.with") }}</h1>
      <div class="account-login-with">
        <a href="javascript:" class="login-face-book"></a>
        <a href="javascript:" class="login-google"></a>
        <a href="javascript:" class="login-wx"></a>
      </div>
      <div class="box-forget">
        <p v-html="$t('login.registerText')"></p>
      </div>
    </div>
    <!--s: h5 login-->
    <UserCenter @on-mask="onClose" v-if="accountStatus" />
  </header>
</template>
<script>
import Header from "./header";
export default Header;
</script>
<style lang="less" scoped>
@import "header";
</style>
