<template>
    <div class="account-handle-box">
        <div class="box-input">
            <input v-model="account" type="text" :placeholder="$t('login.account')" class="input"/>
        </div>
        <div class="box-input">
            <input v-model="password" :type="passwordVisible?'text':'password'"  :placeholder="$t('login.password')" class="input"/>
            <a href="javascript:"  @click="onPasswordShow">{{ passwordVisible?$t('login.hide'):$t('login.show') }}</a>
        </div>
        <div class="box-input">
            <input v-model="newPassword" :type="newPasswordVisible?'text':'password'" :placeholder="$t('login.newPassword')"  class="input"/>
            <a href="javascript:"  @click="onNewPasswordShow">{{ newPasswordVisible?$t('login.hide'):$t('login.show') }}</a>
        </div>
        <div class="input-tips" v-show="tipsVisible">{{ tipsValue }}</div>
        <div class="box-btn">
            <van-button :loading="loadingStatus" type="info" @click="onRestAccountCheck">
                <span v-if="!loadingStatus">{{ $t('login.resetPassword') }}</span>
                <span v-else>{{ $t('login.resetPassword')}}</span>
            </van-button>
            <a href="javascript:" @click="onRestEmailEvent">{{ $t('login.resetEmail') }}</a>
        </div>
    </div>
</template>
<script>
import RestPassword from './restPassword'
export default RestPassword
</script>
<style lang="less">
@import "restPassword";
</style>
